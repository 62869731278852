<template>
    <div class="container-fluid">
        <div class="row">
            <!-- Left-hand Sidebar -->
            <div class="col-md-2 px-0 py-3 border rounded text-white" style="background-color: #1B153E;">
                <div class="sidebar-heading">
                    <img src="/uol-logo.svg" alt="Logo" height="70" class="d-inline-block align-top">
                </div>
                <div class="mx-3 p-3 my-5 border rounded">
                    <h5>Step 1 of 3</h5>
                    <p>Please complete this first page carefully with your personal details.</p>
                    <p>All fields are mandatory and marked with *</p>
                </div>
            </div>

            <!-- Main Content Area -->
            <div class="p-3 col-md-10">
                <header class="my-3">
                    <div class="d-flex justify-content-between align-items-center">
                        <h3 class="m-0">{{ name }}</h3>
                        <span>Updated {{ timeSinceUpdate }}</span>
                    </div>
                </header>
                <h2>University of London Academic Contractor Registration Page</h2>
                <p>Welcome to the University of London, the online portal for contractors to register their interest in
                    working for the University of London.</p>
                <p>The following pages will securely collect the required information to register you with the
                    University of
                    London and issue you with employment documents. We will also provide you with specific documents
                    relating to working with the University of London.</p>
                <p>Your privacy and our obligations to securely collect, store, and process your personal details are
                    paramount. A detailed description of what information we collect, why it is needed, and how we
                    protect
                    it can be found <a href="#">here</a>.</p>
                <p>Please complete the following registration form. Fields marked with * are compulsory:</p>

                <div class="row">
                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-header">
                                <h1>Academic Contractor Registration Form</h1>
                            </div>
                            <div class="card-body">
                                <!-- Display error message if it exists -->
                                <div ref="errorSection" v-if="errorMessage" class="alert alert-danger" role="alert">
                                    {{ errorMessage }}
                                </div>
                                <form @submit.prevent="submitForm" enctype="multipart/form-data" id="signup_form">

                                    <!-- Row 1 -->
                                    <div class="row">
                                        <div class="mb-3 col-md-4">
                                            <label for="title" class="form-label">Title:*</label>
                                            <select v-model="formData.title" class="form-select" id="title">
                                                <option value="" selected></option>
                                                <option value="Mr">Mr</option>
                                                <option value="Ms">Ms</option>
                                                <option value="Miss">Miss</option>
                                                <option value="Mrs">Mrs</option>
                                                <option value="Dr">Dr</option>
                                                <option value="Mx">Mx</option>
                                                <option value="Prof">Prof</option>
                                            </select>
                                        </div>

                                        <div class="mb-3 col-md-4">
                                            <label for="firstName" class="form-label">First Name:*</label>
                                            <input v-model="formData.firstName" type="text" class="form-control"
                                                id="firstName">
                                        </div>

                                        <div class="mb-3 col-md-4">
                                            <label for="middleName" class="form-label">Middle Names:</label>
                                            <input v-model="formData.middleName" type="text" class="form-control"
                                                id="middleName">
                                        </div>
                                    </div>

                                    <!-- Row 2 -->
                                    <div class="row">
                                        <div class="mb-3 col-md-4">
                                            <label for="knownAs" class="form-label">Known As:</label>
                                            <input v-model="formData.knownAs" type="text" class="form-control"
                                                id="knownAs">
                                        </div>
                                        <div class="mb-3 col-md-4">
                                            <label for="lastName" class="form-label">Last Name:*</label>
                                            <input v-model="formData.lastName" type="text" class="form-control"
                                                id="lastName">
                                        </div>
                                        <!--add countryOfResidence UK vs Outside UK as options use bootstrap switches-->
                                        <div class="mb-3 col-md-4">
                                            <label for="livingInUK" class="form-label">Country of
                                                Residence:*</label>
                                            <select v-model="formData.livingInUK" class="form-select" id="livingInUK">
                                                <option value="" selected></option>
                                                <option value="true">United Kingdom</option>
                                                <option value="false">Outside United Kingdom (Inc. Republic of Ireland)
                                                </option>
                                            </select>
                                        </div>
                                        <div class="mb-3 col-md-4">
                                            <label for="address1" class="form-label">Address Line1 <span
                                                    class="right ui-button-icon-primary ui-icon ui-icon-help"></span>:*</label>
                                            <input v-model="formData.address1" type="text" pattern="[a-zA-Z0-9\s?]{3,}"
                                                class="form-control" id="address1" maxlength="30">
                                        </div>
                                        <div class="mb-3 col-md-4">
                                            <label for="address2" class="form-label">Address Line2:</label>
                                            <input v-model="formData.address2" type="text" pattern="[a-zA-Z0-9\s?]{3,}"
                                                class="form-control" id="address2" maxlength="30">
                                        </div>

                                        <div class="mb-3 col-md-4">
                                            <label for="town" class="form-label">Town/City:*</label>
                                            <input v-model="formData.town" type="text" pattern="[a-zA-Z0-9\s?]{3,}"
                                                class="form-control ac_input" id="town" autocomplete="off">
                                        </div>
                                    </div>

                                    <!-- Row 4 -->
                                    <div class="row">
                                        <div class="mb-3 col-md-3">
                                            <label for="county" class="form-label">County:*</label>
                                            <input v-model="formData.county" type="text" pattern="[a-zA-Z0-9\s?]{3,}"
                                                class="form-control" id="county">
                                        </div>

                                        <div class="mb-3 col-md-3">
                                            <label for="postcode" class="form-label">Postcode:*</label>
                                            <input v-model="formData.postcode" type="text" pattern="[a-zA-Z0-9\s?]{3,}"
                                                class="form-control" id="postcode">
                                        </div>

                                        <div class="mb-3 col-md-3">
                                            <label for="phone" class="form-label">Telephone:*</label>
                                            <input v-model="formData.phone" type="text" class="form-control" id="phone">
                                        </div>

                                        <div class="mb-3 col-md-3">
                                            <label for="mobile" class="form-label">Mobile:*</label>
                                            <input v-model="formData.mobile" type="text" class="form-control"
                                                id="mobile">
                                        </div>
                                    </div>

                                    <!-- Row 6 -->
                                    <div class="row">
                                        <div class="mb-3 col-md-3" v-if="formData.livingInUK === 'true'">
                                            <label class="form-label">Are you Student Visa holder?</label>
                                            <div>
                                                <input v-model="formData.studentVisa" type="radio"
                                                    class="form-check-input" value="true" id="studentVisa1">
                                                <label for="studentVisa1" class="form-check-label">Yes</label>
                                            </div>
                                            <div>
                                                <input v-model="formData.studentVisa" type="radio"
                                                    class="form-check-input" value="false" id="student2">
                                                <label for="studentVisa2" class="form-check-label">No</label>
                                            </div>
                                        </div>

                                        <div class="mb-3 col-md-3">
                                            <!--add date of birth-->
                                            <label for="dob" class="form-label">Date of Birth:*</label>
                                            <input v-model="formData.dob" type="date" class="form-control" id="dob">
                                        </div>
                                        <!--add nationalities-->
                                        <div class="mb-3 col-md-3">
                                            <label for="nationality" class="form-label">My Nationality is:</label>
                                            <select aria-label="Nationality" name="nationality" class="form-select"
                                                required="required" id="nationality">
                                                <option value=""></option>
                                                <option value="Afghan">Afghan</option>
                                                <option value="Albanian">Albanian</option>
                                                <option value="Algerian">Algerian</option>
                                                <option value="American">American</option>
                                                <option value="Andorran">Andorran</option>
                                                <option value="Angolan">Angolan</option>
                                                <option value="Antiguan">Antiguan</option>
                                                <option value="Argentinean">Argentinean</option>
                                                <option value="Armenian">Armenian</option>
                                                <option value="Australian">Australian</option>
                                                <option value="Austrian">Austrian</option>
                                                <option value="Azerbaijani">Azerbaijani</option>
                                                <option value="Bahamian">Bahamian</option>
                                                <option value="Bahraini">Bahraini</option>
                                                <option value="Bangladeshi">Bangladeshi</option>
                                                <option value="Barbadian">Barbadian</option>
                                                <option value="Barbudans">Barbudans</option>
                                                <option value="Batswana">Batswana</option>
                                                <option value="Belarusian">Belarusian</option>
                                                <option value="Belgian">Belgian</option>
                                                <option value="Belizean">Belizean</option>
                                                <option value="Beninese">Beninese</option>
                                                <option value="Bhutanese">Bhutanese</option>
                                                <option value="Bolivian">Bolivian</option>
                                                <option value="Bosnian">Bosnian</option>
                                                <option value="Brazilian">Brazilian</option>
                                                <option value="British" selected="selected">British</option>
                                                <option value="Bruneian">Bruneian</option>
                                                <option value="Bulgarian">Bulgarian</option>
                                                <option value="Burkinabe">Burkinabe</option>
                                                <option value="Burmese">Burmese</option>
                                                <option value="Burundian">Burundian</option>
                                                <option value="Cambodian">Cambodian</option>
                                                <option value="Cameroonian">Cameroonian</option>
                                                <option value="Canadian">Canadian</option>
                                                <option value="Cape Verdean">Cape Verdean</option>
                                                <option value="Central African">Central African</option>
                                                <option value="Chadian">Chadian</option>
                                                <option value="Chilean">Chilean</option>
                                                <option value="Chinese">Chinese</option>
                                                <option value="Colombian">Colombian</option>
                                                <option value="Comoran">Comoran</option>
                                                <option value="Congolese">Congolese</option>
                                                <option value="Costa Rican">Costa Rican</option>
                                                <option value="Croatian">Croatian</option>
                                                <option value="Cuban">Cuban</option>
                                                <option value="Cypriot">Cypriot</option>
                                                <option value="Czech">Czech</option>
                                                <option value="Danish">Danish</option>
                                                <option value="Djibouti">Djibouti</option>
                                                <option value="Dominican">Dominican</option>
                                                <option value="Dutch">Dutch</option>
                                                <option value="East Timorese">East Timorese</option>
                                                <option value="Ecuadorean">Ecuadorean</option>
                                                <option value="Egyptian">Egyptian</option>
                                                <option value="Emirian">Emirian</option>
                                                <option value="Equatorial Guinean">Equatorial Guinean</option>
                                                <option value="Eritrean">Eritrean</option>
                                                <option value="Estonian">Estonian</option>
                                                <option value="Ethiopian">Ethiopian</option>
                                                <option value="Fijian">Fijian</option>
                                                <option value="Filipino">Filipino</option>
                                                <option value="Finnish">Finnish</option>
                                                <option value="French">French</option>
                                                <option value="Gabonese">Gabonese</option>
                                                <option value="Gambian">Gambian</option>
                                                <option value="Georgian">Georgian</option>
                                                <option value="German">German</option>
                                                <option value="Ghanaian">Ghanaian</option>
                                                <option value="Greek">Greek</option>
                                                <option value="Grenadian">Grenadian</option>
                                                <option value="Guatemalan">Guatemalan</option>
                                                <option value="Guinea-Bissauan">Guinea-Bissauan</option>
                                                <option value="Guinean">Guinean</option>
                                                <option value="Guyanese">Guyanese</option>
                                                <option value="Haitian">Haitian</option>
                                                <option value="Herzegovinian">Herzegovinian</option>
                                                <option value="Honduran">Honduran</option>
                                                <option value="Hungarian">Hungarian</option>
                                                <option value="I-Kiribati">I-Kiribati</option>
                                                <option value="Icelander">Icelander</option>
                                                <option value="Indian">Indian</option>
                                                <option value="Indonesian">Indonesian</option>
                                                <option value="Iranian">Iranian</option>
                                                <option value="Iraqi">Iraqi</option>
                                                <option value="Irish">Irish</option>
                                                <option value="Israeli">Israeli</option>
                                                <option value="Italian">Italian</option>
                                                <option value="Ivorian">Ivorian</option>
                                                <option value="Jamaican">Jamaican</option>
                                                <option value="Japanese">Japanese</option>
                                                <option value="Jordanian">Jordanian</option>
                                                <option value="Kazakhstani">Kazakhstani</option>
                                                <option value="Kenyan">Kenyan</option>
                                                <option value="Kittian and Nevisian">Kittian and Nevisian</option>
                                                <option value="Kuwaiti">Kuwaiti</option>
                                                <option value="Kyrgyz">Kyrgyz</option>
                                                <option value="Laotian">Laotian</option>
                                                <option value="Latvian">Latvian</option>
                                                <option value="Lebanese">Lebanese</option>
                                                <option value="Liberian">Liberian</option>
                                                <option value="Libyan">Libyan</option>
                                                <option value="Liechtensteiner">Liechtensteiner</option>
                                                <option value="Lithuanian">Lithuanian</option>
                                                <option value="Luxembourger">Luxembourger</option>
                                                <option value="Macedonian">Macedonian</option>
                                                <option value="Malagasy">Malagasy</option>
                                                <option value="Malawian">Malawian</option>
                                                <option value="Malaysian">Malaysian</option>
                                                <option value="Maldivan">Maldivan</option>
                                                <option value="Malian">Malian</option>
                                                <option value="Maltese">Maltese</option>
                                                <option value="Marshallese">Marshallese</option>
                                                <option value="Mauritanian">Mauritanian</option>
                                                <option value="Mauritian">Mauritian</option>
                                                <option value="Mexican">Mexican</option>
                                                <option value="Micronesian">Micronesian</option>
                                                <option value="Moldovan">Moldovan</option>
                                                <option value="Monacan">Monacan</option>
                                                <option value="Mongolian">Mongolian</option>
                                                <option value="Moroccan">Moroccan</option>
                                                <option value="Mosotho">Mosotho</option>
                                                <option value="Motswana">Motswana</option>
                                                <option value="Mozambican">Mozambican</option>
                                                <option value="Namibian">Namibian</option>
                                                <option value="Nauruan">Nauruan</option>
                                                <option value="Nepalese">Nepalese</option>
                                                <option value="New Zealander">New Zealander</option>
                                                <option value="Ni-Vanuatu">Ni-Vanuatu</option>
                                                <option value="Nicaraguan">Nicaraguan</option>
                                                <option value="Nigerian">Nigerian</option>
                                                <option value="Nigerien">Nigerien</option>
                                                <option value="North Korean">North Korean</option>
                                                <option value="Norwegian">Norwegian</option>
                                                <option value="Omani">Omani</option>
                                                <option value="Pakistani">Pakistani</option>
                                                <option value="Palauan">Palauan</option>
                                                <option value="Panamanian">Panamanian</option>
                                                <option value="Papua New Guinean">Papua New Guinean</option>
                                                <option value="Paraguayan">Paraguayan</option>
                                                <option value="Peruvian">Peruvian</option>
                                                <option value="Polish">Polish</option>
                                                <option value="Portuguese">Portuguese</option>
                                                <option value="Qatari">Qatari</option>
                                                <option value="Romanian">Romanian</option>
                                                <option value="Russian">Russian</option>
                                                <option value="Rwandan">Rwandan</option>
                                                <option value="Saint Lucian">Saint Lucian</option>
                                                <option value="Salvadoran">Salvadoran</option>
                                                <option value="Samoan">Samoan</option>
                                                <option value="San Marinese">San Marinese</option>
                                                <option value="Sao Tomean">Sao Tomean</option>
                                                <option value="Saudi">Saudi</option>
                                                <option value="Senegalese">Senegalese</option>
                                                <option value="Serbian">Serbian</option>
                                                <option value="Seychellois">Seychellois</option>
                                                <option value="Sierra Leonean">Sierra Leonean</option>
                                                <option value="Singaporean">Singaporean</option>
                                                <option value="Slovakian">Slovakian</option>
                                                <option value="Slovenian">Slovenian</option>
                                                <option value="Solomon Islander">Solomon Islander</option>
                                                <option value="Somali">Somali</option>
                                                <option value="South African">South African</option>
                                                <option value="South Korean">South Korean</option>
                                                <option value="Spanish">Spanish</option>
                                                <option value="Sri Lankan">Sri Lankan</option>
                                                <option value="Sudanese">Sudanese</option>
                                                <option value="Surinamer">Surinamer</option>
                                                <option value="Swazi">Swazi</option>
                                                <option value="Swedish">Swedish</option>
                                                <option value="Swiss">Swiss</option>
                                                <option value="Syrian">Syrian</option>
                                                <option value="Taiwanese">Taiwanese</option>
                                                <option value="Tajik">Tajik</option>
                                                <option value="Tanzanian">Tanzanian</option>
                                                <option value="Thai">Thai</option>
                                                <option value="Togolese">Togolese</option>
                                                <option value="Tongan">Tongan</option>
                                                <option value="Trinidadian or Tobagonian">Trinidadian or Tobagonian
                                                </option>
                                                <option value="Tunisian">Tunisian</option>
                                                <option value="Turkish">Turkish</option>
                                                <option value="Tuvaluan">Tuvaluan</option>
                                                <option value="Ugandan">Ugandan</option>
                                                <option value="Ukrainian">Ukrainian</option>
                                                <option value="Uruguayan">Uruguayan</option>
                                                <option value="Uzbekistani">Uzbekistani</option>
                                                <option value="Venezuelan">Venezuelan</option>
                                                <option value="Vietnamese">Vietnamese</option>
                                                <option value="Yemenite">Yemenite</option>
                                                <option value="Zambian">Zambian</option>
                                                <option value="Zimbabwean">Zimbabwean</option>
                                                <option value="unknown">unknown</option>
                                                <option value="Montenegrin">Montenegrin</option>
                                                <option value="Aruban">Aruban</option>
                                                <option value="Botswanan">Botswanan</option>
                                                <option value="curaçaoan">curaçaoan</option>
                                                <option value="Gabonian">Gabonian</option>
                                                <option value="Kiribatian">Kiribatian</option>
                                                <option value="Basotho">Basotho</option>
                                                <option value="Macanese">Macanese</option>
                                                <option value="Palestinian">Palestinian</option>
                                                <option value="Saint Vincentian">Saint Vincentian</option>
                                                <option value="Turkmen">Turkmen</option>
                                                <option value="Kosovan">Kosovan</option>
                                                <option value="Hong Kongese">Hong Kongese</option>
                                            </select>
                                        </div>
                                        <!--add ni number-->
                                        <div class="mb-3 col-md-3">
                                            <label for="nino" class="form-label">National Insurance Number:*</label>
                                            <input v-model="formData.nino" type="text" class="form-control" id="nino">
                                        </div>

                                    </div>

                                    <!--add username,password and repeat password as row-->
                                    <div class="row">
                                        <div class="mb-3 col-md-4">
                                            <label for="email" class="form-label">Email:*</label>
                                            <input v-model="formData.email" type="email" class="form-control"
                                                id="email" autocomplete="username">
                                        </div>
                                        <div class="mb-3 col-md-4">
                                            <label for="password" class="form-label">Password:*</label>
                                            <input v-model="formData.password" type="password" class="form-control"
                                                id="password" autocomplete="new-password">
                                        </div>
                                        <div class="mb-3 col-md-4">
                                            <label for="repeatPassword" class="form-label">Repeat Password:*</label>
                                            <input v-model="formData.repeatPassword" type="password"
                                                class="form-control" id="repeatPassword" autocomplete="new-password">
                                        </div>
                                    </div>
                                    <!-- Row 10 -->
                                    <div class="row">
                                        <div class="mb-3 col-md-12">
                                            <div class="form-check">
                                                <input v-model="formData.agreeTerms" type="checkbox"
                                                    class="form-check-input" id="agreeTerms">
                                                <label for="agreeTerms" class="form-check-label">I have read the privacy
                                                    notice and acceptable use policy.</label>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- Row 11 -->
                                    <div class="row">
                                        <div class="mb-3 col-md-12 text-end">
                                            <button type="submit" class="btn btn-primary"
                                                :disabled="formData.studentVisa === 'true' && formData.agreeTerms !== 'true'">Continue
                                                to Step 2 &raquo;</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal for "Cannot accept student visa students" -->
    <div class="modal" id="studentVisaRightToWorkModal" tabindex="-1" aria-labelledby="studentVisaRightToWorkModalLabel"
        aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="studentVisaRightToWorkModalLabel">Cannot Accept Student Visa Holders
                    </h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <p>Your registration with UOL Academic Contractors has been declined due to your student visa
                        status.
                    </p>
                    <p>Individuals who have entered the UK on a student visa are subject to restrictions relating to
                        what
                        they can and cannot do whilst in the UK, as per guidance from the Home Office which you can find
                        <a href="https://www.gov.uk/student-visa" target="_blank">here</a>.
                    </p>
                    <p>If you have any further queries, please contact your programme contact.</p>
                    <p>Best wishes,</p>
                    <p>Academic Contracts Team</p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { ref, onMounted, watch, nextTick, computed } from 'vue';
import { Modal } from 'bootstrap';
import { signUp as amplifySignUp, signIn as amplifySignIn } from 'aws-amplify/auth';
import { generateClient } from 'aws-amplify/api';
import { contractorRegisterStep1Input } from '@/graphql/mutations';
import { v4 as uuidv4 } from 'uuid';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

export default {
    setup() {
        const store = useStore();
        const name = ref('');
        const timeSinceUpdate = ref('less than a minute ago');
        const studentVisaModal = ref(null);
        const errorMessage = ref('');
        const errorSection = ref(null);
        const client = generateClient();
        const guid = uuidv4();
        const router = useRouter(); // Using useRouter to access the router instance

        // Utilize mapGetters within the setup function using the computed composition API
        const formData = computed(() => store.state.step1Data);
        console.log(formData.value);

        watch(() => formData.value.studentVisa, (newValue) => {
            if (newValue) {
                const modal = new Modal(document.getElementById('studentVisaRightToWorkModal'));
                modal.show();
            }
        });

        const submitForm = async () => {
            try {
                const signUpResponse = await amplifySignUp({
                    username: formData.value.email,
                    password: formData.value.password,
                    attributes: {
                        email: formData.value.email,
                    },
                });
                // Now, sign in the user immediately after sign-up
                const signInResponse = await amplifySignIn({username:formData.value.email, password:formData.value.password});
                console.log('User successfully signed in after registration:', signInResponse);
                

                const contractorData = {
                    id: guid,
                    ...formData.value,
                    livingInUK: formData.value.livingInUK.toString(),
                    studentVisa: formData.value.studentVisa.toString(),
                    agreeTerms: formData.value.agreeTerms.toString()
                };

                delete contractorData.password;
                delete contractorData.repeatPassword;

                // console.log('Creating academic contractor:', JSON.stringify(contractorData));

                const response = await client.graphql({
                    query: contractorRegisterStep1Input,
                    variables: { input: contractorData },
                });

                console.log('Academic contractor created:', response);
                localStorage.setItem('userid', response.data.contractorRegisterStep1Input.id);
                router.push('/register/step2');
            } catch (error) {
                errorMessage.value = `${error.message}`;
                nextTick(() => {
                    errorSection.value && errorSection.value.scrollIntoView({ behavior: 'smooth' });
                });
            }
        };

        onMounted(() => {
            studentVisaModal.value = new Modal(document.getElementById('studentVisaRightToWorkModal'));
            setInterval(() => {
                timeSinceUpdate.value = 'More than a minute ago';
            }, 60000);
        });

        return {
            name,
            submitForm,
            timeSinceUpdate,
            studentVisaModal,
            errorMessage,
            errorSection,
            formData,
        };
    },
};
</script>

<style>
/* Optional styling */
header {
    background-color: #f8f9fa;
    padding: 10px;
    border-radius: 5px;
}
</style>
