<template>
    <div class="container mt-3" v-if="contractor">
        <div class="row">
            <div class="col-md-3">
                <!-- Profile Picture and Basic Info -->
                <div class="card">
                    <img :src="contractor.profilePic" class="card-img-top" alt="Profile Picture">
                    <div class="card-body">
                        <h5 class="card-title">{{ contractor.name }}</h5>
                        <p class="card-text">{{ contractor.organisation }}</p>
                        <router-link :to="{ name: 'OfferAppointment', params: { id: contractor.id } }"
                            v-if="contractor.isCompliant === true">
                            <button class="btn btn-primary btn-sm">
                                Offer Appointment
                            </button>
                        </router-link>
                        <button v-else class="btn btn-warning btn-sm" ref="requestDocumentsBtn" data-bs-toggle="popover"
                            onclick="alert('Documents requested.')">
                            Request Documents
                        </button>
                    </div>
                </div>
            </div>
            <div class="col-md-8">
                <!-- Detailed Info -->
                <div class="card">
                    <div class="card-body">
                        <h5 class="card-title">Profile Details</h5>
                        <p class="card-text">{{ contractor.personalStatement }}</p>
                        <hr>

                        <!-- Employment History Section -->
                        <h5 class="card-title">Roles</h5>

                        <div v-for="(job, index) in contractor.employmentHistory" :key="index" class="list-group mb-3">
                            <div class="list-group-item">
                                <h6 class="mb-1">{{ job.jobTitle }} at {{ job.employer }}</h6>
                                <p class="mb-1">{{ job.startDate }} - {{ job.endDate }}</p>
                                <p class="mb-1">{{ job.description }}</p>
                            </div>
                        </div>
                        <!-- Compliance Link Button -->
                        <div class="mt-3 text-end">
                            <router-link to="/contractor/my-compliance" >
                               <button class="btn btn-sm btn-primary"><i class="bi bi-shield-lock-fill"></i> Go to Compliance</button> 
                            </router-link>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-else>
        <p>Contractor not found.</p>
    </div>
</template>
<script>
import { ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import contractorsData from '../../mock/contractors.json';
import { createPopper, Popover } from 'bootstrap'; // Import Popover from Bootstrap 5

export default {
    setup() {
        const route = useRoute();
        const contractor = ref(null);

        onMounted(() => {
            const contractorId = parseInt(route.params.id);
            const matchedContractor = contractorsData.find(c => c.id === contractorId);
            contractor.value = matchedContractor;

            // Initialize popover using Popper from Bootstrap 5
            const requestDocumentsBtn = document.querySelector('[data-bs-toggle="popover"]');
            if (requestDocumentsBtn) {
                requestDocumentsBtn.addEventListener('click', function () {
                    const popover = new Popover(requestDocumentsBtn, {
                        content: 'An email has been sent requesting documents.',
                        trigger: 'focus'
                    });
                    popover.show();
                });
            }
        });

        return {
            contractor
        };
    }
};
</script>
