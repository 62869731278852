<template>
  <aside class="bg-uol border-right vh-100 overflow-auto" id="sidebar-wrapper">
    <div class="sidebar-heading">
      <img src="/uol-logo.svg" alt="Logo" height="70" class="d-inline-block align-top">
    </div>
    <div class="sidebar-heading">Account Management</div>
    <div class="list-group list-group-flush bg-uol">
      <router-link to="/manager/" v-if="userType === 'C'" class="list-group-item list-group-item-action bg-uol"><i class="bi bi-house"></i> Consultant Home</router-link>
      <router-link to="/manager/" v-else class="list-group-item list-group-item-action bg-uol"><i class="bi bi-house"></i> Manager Home</router-link>

      <!-- Trigger button for offcanvas -->
      <a href="#" class="list-group-item list-group-item-action bg-uol" data-bs-toggle="offcanvas" data-bs-target="#notificationsOffcanvas" aria-controls="notificationsOffcanvas">
        <i class="bi bi-bell"></i> Notifications <span class="badge badge-info">{{ notifications.length }}</span>
      </a>

      <router-link v-if="userType === 'C'" to="/manager/invite-colleague" class="list-group-item list-group-item-action bg-uol"><i class="bi bi-envelope"></i> Invite a Colleague</router-link>

      <div class="sidebar-heading">Talent Bank</div>
      <router-link to="/manager/search-contractors" class="list-group-item list-group-item-action bg-uol"><i class="bi bi-search"></i> My Contractors</router-link>
      <router-link to="/manager/invite-contractors" class="list-group-item list-group-item-action bg-uol"><i class="bi bi-envelope-plus"></i> Invite Contractors</router-link>

      <div class="sidebar-heading">Programmes</div>
      <router-link to="/manager/manage-programmes" class="list-group-item list-group-item-action bg-uol"><i class="bi bi-journal-text"></i> Manage Programmes</router-link>
      <router-link v-if="userType === 'C'" to="/manager/pending-tasks" class="list-group-item list-group-item-action bg-uol"><i class="bi bi-clock-history"></i> EEIE Nominations</router-link>

      <div class="sidebar-heading">Settings</div>
      <router-link to="/manager/change-password" class="list-group-item list-group-item-action bg-uol"><i class="bi bi-key"></i> Change Password</router-link>
      <router-link to="/manager/change-password" class="list-group-item list-group-item-action bg-uol"><i class="bi bi-question-circle"></i> Support</router-link>
      <router-link to="/logout" class="list-group-item list-group-item-action bg-uol"><i class="bi bi-box-arrow-right"></i> Logout</router-link>
    </div>
  </aside>
  <!-- Offcanvas element -->
  <div class="offcanvas offcanvas-end bg-uol" tabindex="-1" id="notificationsOffcanvas" aria-labelledby="offcanvasNotificationsLabel">
    <div class="offcanvas-header">
      <h5 id="offcanvasNotificationsLabel">Notifications</h5>
      <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
    <div class="offcanvas-body bg-uol text-dark">
      <ul class="list-group list-group-flush" style="background-color: #1B153E;">
        <li v-for="(notification, index) in notifications" :key="index" class="list-group-item list-group-item-action" style="background-color: #1B153E;">
          <a :href="notification.link" class="d-flex align-items-center text-white" style="text-decoration: none;">
            <i class="bi bi-exclamation-circle-fill me-2"></i> {{ notification.message }}
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import notificationsData from '../mock/notifications.json';

export default {
  name: 'ManagerSidebar',
  setup() {
    const userType = ref(localStorage.getItem('userType') || 'M');
    const notifications = ref([]);

    onMounted(() => {
      notifications.value = notificationsData;
    });

    return {
      userType,
      notifications
    };
  },
};
</script>

<style>
/* Ensure the offcanvas appears above other content */
.offcanvas {
    z-index: 1050; /* Standard Bootstrap modal z-index is 1050, adjust if needed */
}

/* Additional styling to ensure the backdrop is visible and above other content but below the offcanvas */
.offcanvas-backdrop {
    z-index: 1040; /* Just below the offcanvas */
}
</style>
