<template>
    <div class="container mt-4">
      <h2 class="mb-4"><i class="bi bi-life-preserver"></i> Help & Support</h2>
      <div class="row">
        <!-- Email Us Card -->
        <div class="col-md-4">
          <div class="card">
            <div class="card-header">
              <h4><i class="bi bi-envelope-fill"></i> Email Us</h4>
            </div>
            <div class="card-body">
              <p><i class="bi bi-chevron-right"></i> Onboarding/Examiner Contracts team:<br> <a href="mailto:examinercontracts@london.ac.uk">examinercontracts@london.ac.uk</a></p>
              <p><i class="bi bi-chevron-right"></i> Fees Office:<br> <a href="mailto:examiners.fees@london.ac.uk">examiners.fees@london.ac.uk</a></p>
            </div>
          </div>
        </div>
        
        <!-- Call Us Card -->
        <div class="col-md-4">
          <div class="card">
            <div class="card-header">
              <h4><i class="bi bi-telephone-fill"></i> Call Us</h4>
            </div>
            <div class="card-body">
              <p><i class="bi bi-chevron-right"></i> Onboarding/Examiner Contracts team:<br> <a href="tel:+442078628552">0207 862 8552</a></p>
              <p><i class="bi bi-chevron-right"></i> Fees Office:<br> <a href="tel:+442076645590">0207 664 5590</a></p>
            </div>
          </div>
        </div>
        
        <!-- Address Card -->
        <div class="col-md-4">
          <div class="card">
            <div class="card-header">
              <h4><i class="bi bi-geo-alt-fill"></i> Address</h4>
            </div>
            <div class="card-body">
              <p> Senate House,<br>
                 Malet Street,<br>
                 London,<br>
                 United Kingdom<br>
                 WC1E 7HU
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'HelpSupport'
  };
  </script>
  
  <style>
  .card-header h4 {
    color: #0275d8;
  }
  
  .card-body p {
    font-size: 1em;
  }
  
  .card-body a {
    color: #0275d8;
  }
  
  .card-body a:hover {
    text-decoration: underline;
  }
  </style>
  