<template>
  <div id="app">
    <!-- Manager layout -->
    <div v-if="isManagerLayout" class="default-layout">
      <ManagerHeader />

      <div class="content d-flex mb-5">
        <ManagerSidebar class="manager-sidebar" />
        <main class="flex-grow-1 p-1" style="margin-left: 250px;">
          <router-view></router-view>
        </main>
      </div>
      <div class="mt-5">
        <ManagerFooter />
      </div>
    </div>
    <div v-else-if="isCandidateLayout" class="default-layout">
      <CandidateHeader />

      <div class="content d-flex mb-5">
        <CandidateSidebar class="default-sidebar" />
        <main class="flex-grow-1 p-1" style="margin-left: 250px;">
          <router-view></router-view>
        </main>
        <div class="mt-5">
          <CandidateFooter />
        </div>
      </div>

    </div>
    <!-- Non-manager layout -->
    <div v-else>
      <router-view></router-view> <!-- Displayed for non-manager routes -->
    </div>
  </div>
</template>

<script>
import ManagerHeader from './components/ManagerHeader.vue';
import ManagerSidebar from './components/ManagerSidebar.vue';
import ManagerFooter from './components/ManagerFooter.vue';
import CandidateHeader from './components/CandidateHeader.vue';
import CandidateSidebar from './components/CandidateSidebar.vue';
import CandidateFooter from './components/CandidateFooter.vue';
import 'bootstrap/dist/css/bootstrap.css'; // Import Bootstrap CSS

export default {
  name: 'App',
  components: {
    ManagerHeader,
    ManagerSidebar,
    ManagerFooter,
    CandidateHeader,
    CandidateSidebar,
    CandidateFooter
  },
  computed: {
    isManagerLayout() {
      return this.$route.meta.layout === 'manager';
    },
    isCandidateLayout() {
      return this.$route.meta.layout === 'candidate' || this.$route.meta.layout === 'contractor';
    }
  }
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

/* Manager layout specific styles */
.default-layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.default-layout .content {
  flex-grow: 1;
  display: flex;
}

.manager-sidebar {
  width: 250px;
  flex-shrink: 0;
  /* Prevents the sidebar from shrinking */
}

.manager-main {
  flex-grow: 1;
  padding: 1rem;
  /* Adjust as needed */
}

.container {
  margin-left: 0px;
}

@media (max-width: 768px) {
  .manager-sidebar {
    width: 100%;
    order: -1;
    /* Moves the sidebar to the top on small screens */
  }
}
</style>
