<template>
    <div class="container mt-2">
      <div class="card">
        <div class="card-body">
          <h2 class="card-title">{{ formattedViewName }}</h2>
          <p>Coming Soon</p>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    computed: {
      formattedViewName() {
        // Grabs the route name and adds a space before each capital letter
        return this.$route.name.replace(/([A-Z])/g, ' $1').trim();
      }
    }
  };
  </script>
  