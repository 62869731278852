<template>
    <div class="container mt-3" v-if="complianceDocuments">
      <div class="row">
        <div class="col-md-12">
          <h2 class="mb-4"><i class="bi bi-file-earmark-check-fill"></i> My Compliance Documents</h2>
          <div class="card mb-3" v-if="complianceDocuments.nationality">
            <div class="card-body">
              <h5 class="card-title"><i class="bi bi-globe"></i> Nationality Proof</h5>
              <p class="card-text"><i class="bi bi-card-text"></i> Document Type: Nationality Confirmation</p>
              <p class="card-text"><i class="bi bi-calendar3"></i> Upload Date: {{ complianceDocuments.nationality.uploadDate }}</p>
              <p class="card-text"><i class="bi bi-person-check-fill"></i> Verified By: {{ complianceDocuments.nationality.verifiedBy }}</p>
            </div>
          </div>
  
          <div class="card mb-3" v-if="complianceDocuments.passport">
            <div class="card-body">
              <h5 class="card-title"><i class="bi bi-passport"></i> Passport</h5>
              <p class="card-text"><i class="bi bi-card-list"></i> Document Type: EU Passport</p>
              <p class="card-text"><i class="bi bi-calendar-event"></i> Expiry Date: {{ complianceDocuments.passport.expiryDate }}</p>
              <p class="card-text"><i class="bi bi-calendar3"></i> Upload Date: {{ complianceDocuments.passport.uploadDate }}</p>
              <p class="card-text"><i class="bi bi-person-check-fill"></i> Verified By: {{ complianceDocuments.passport.verifiedBy }}</p>
            </div>
          </div>
  
          <div class="card" v-if="complianceDocuments.visa">
            <div class="card-body">
              <h5 class="card-title"><i class="bi bi-card-checklist"></i> Visa/Work Permit</h5>
              <p class="card-text"><i class="bi bi-card-list"></i> Document Type: Work Visa</p>
              <p class="card-text"><i class="bi bi-calendar-event"></i> Expiry Date: {{ complianceDocuments.visa.expiryDate }}</p>
              <p class="card-text"><i class="bi bi-calendar3"></i> Upload Date: {{ complianceDocuments.visa.uploadDate }}</p>
              <p class="card-text"><i class="bi bi-person-check-fill"></i> Verified By: {{ complianceDocuments.visa.verifiedBy }}</p>
            </div>
          </div>
  
        </div>
      </div>
    </div>
    <div v-else>
      <p>No compliance documents found.</p>
    </div>
  </template>
  
  <script>
  import { ref, onMounted } from 'vue';
  import complianceDocumentsData from '../../mock/rtw-docs.json';
  
  export default {
    setup() {
      const complianceDocuments = ref(null);
  
      onMounted(() => {
        // Assuming ID 3 for the logged-in contractor
        const contractorComplianceDocs = complianceDocumentsData.find(doc => doc.id === 3);
        complianceDocuments.value = contractorComplianceDocs;
      });
  
      return {
        complianceDocuments
      };
    }
  };
  </script>
  