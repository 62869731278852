// Import the required functions
import { signIn, signOut, fetchAuthSession} from '@aws-amplify/auth';

// signIn function with storing data in local storage
export const signInUser = async (username, password) => {
  try {
    const user = await signIn(username, password);

    // Store tokens and user details in local storage
    localStorage.setItem('userId', user.username); // or user.attributes.sub for the Cognito User ID
    localStorage.setItem('token', user.signInUserSession.idToken.jwtToken);

    // Encode and store first name, last name, and email
    const { email, given_name, family_name } = user.attributes;
    localStorage.setItem('email', btoa(email));
    localStorage.setItem('firstName', btoa(given_name));
    localStorage.setItem('lastName', btoa(family_name));

    // Store session time for validation
    localStorage.setItem('sessionTime', new Date().getTime());

    return user;
  } catch (error) {
    console.error('Error signing in', error);
    throw error; // Rethrow the error for handling it in the calling function
  }
};


// signOut function with clearing local storage
export const signOutUser = async () => {
  try {
    await signOut();
    localStorage.removeItem('userId');
    localStorage.removeItem('token');
    localStorage.removeItem('email');
    localStorage.removeItem('firstName');
    localStorage.removeItem('lastName');
    localStorage.removeItem('sessionTime');
  } catch (error) {
    console.error('Error signing out', error);
    throw error;
  }
};

// Method to check if user is "logged in" based on localStorage data
export const getCurrentAuthenticatedUser = () => {
  const checkTime = localStorage.getItem('checkTime') !== undefined ? parseInt(localStorage.getItem('checkTime')) : 0;
  const oneHour = 3600000; // 1 hour in milliseconds
  const now = new Date().getTime();

  if (now - checkTime > oneHour) {
    // More than an hour has passed since last check
    refreshUserDetails(); // Call the refresh method
  }

  const username = localStorage.getItem('username');
  const sub = localStorage.getItem('sub');

  // Check if both username and sub exist in localStorage
  if (username && sub) {
    return {
      username: username,
      sub: sub
    };
  } else {
    console.log('No user logged in');
    return null;
  }
};

export const getUserDetailsFromLocalStorage = () => {
  const username = localStorage.getItem('username');

  return {
    username: username ? atob(username) : null
  };
};

export const refreshUserDetails = async () => {
  try {
    // Placeholder for actual user refresh logic
    // For example, a call to your backend or AWS Cognito to validate and fetch user details
    const updatedUserDetails = await getUpdatedUserDetailsFromBackend();

    // Update localStorage with new details
    localStorage.setItem('username', updatedUserDetails.username);
    localStorage.setItem('sub', updatedUserDetails.sub);
    localStorage.setItem('firstName', updatedUserDetails.firstName);
    localStorage.setItem('lastName', updatedUserDetails.lastName);

    // Update the check time
    const now = new Date().getTime();
    localStorage.setItem('checkTime', now.toString());

    console.log('User details refreshed');
  } catch (error) {
    console.error('Failed to refresh user details:', error);
    // Handle error, possibly by logging out the user or redirecting to login page
  }
};

export const getUpdatedUserDetailsFromBackend = async () => {
  try {
    const session = await fetchAuthSession();

    if (session && session.tokens) {
      const payload = session.tokens.accessToken.payload;

      // Extract username and sub
      const username = payload.username;
      const sub = payload.sub;
      const firstName = payload.given_name;
      const lastName = payload.family_name;

      // Return the updated user details
      return { username, sub, firstName, lastName };
    } else {
      throw new Error('Session tokens are undefined');
    }
  } catch (error) {
    console.error('Error fetching updated user details:', error);
    throw error; // Rethrow the error to be handled by the caller
  }
};


