<template>
    <div class="container mt-5">
      <div class="row justify-content-center">
        <div class="col-md-6">
          <div class="card">
            <div class="card-header">Login</div>
            <div class="card-body">
              <form @submit.prevent="onSubmit">
                <div class="form-group">
                  <label for="email">Email address</label>
                  <input type="email" class="form-control" id="email" v-model="email">
                </div>
                <div class="form-group">
                  <label for="password">Password</label>
                  <input type="password" class="form-control" id="password" v-model="password">
                </div>
                <button type="submit" class="btn btn-primary m-1">Submit</button>
                <button class="btn btn-secondary m-1" @click="samlLogin">Login with UoL Email</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
    import { signIn } from '@aws-amplify/auth';
    import { signInWithRedirect } from '@aws-amplify/auth';

    export default {
    data() {
        return {
        username: '', // Changed from 'email' to 'username' to match data property
        password: '',
        };
    },
    methods: {
        async login(event) { // Renamed from 'onSubmit' to 'login'
        event.preventDefault();
        try {
            await signIn(this.username, this.password); // Using 'username' here
            // Redirect or perform actions after successful login
        } catch (error) {
            console.error('Login error', error);
            // Handle login error
        }
        },
        samlLogin() {
          signInWithRedirect({ provider: "UoL-Academic-Contractors-TestEnv" });
        },
    },
    };
    </script>
