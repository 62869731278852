<template>
  <div class="container mt-4">
    <div class="card" style="min-height: 200px;">
      <div class="card-body">
        <h1 class="card-title">Invite a Contractor</h1>
        <div class="row">
          <div class="col-auto">
            <div class="form-group">
              <label for="name">Title:*</label>
              <!--drop down Mr, Mrs, Ms, Miss, Mx, Dr, Prof in alphabetical order-->
              <select class="form-control" id="title" name="title">
                <option value="Mr">Mr</option>
                <option value="Ms">Ms</option>
                <option value="Mrs">Mrs</option>
                <option value="Miss">Miss</option>
                <option value="Mx">Mx</option>
                <option value="Dr">Dr</option>
                <option value="Prof">Prof</option>
              </select>
            </div>
          </div>
          <div class="col-auto">
            <div class="form-group">
              <label for="name">First Name:*</label>
              <input class="form-control" type="text" id="name" name="name">
            </div>
          </div>
          <div class="col-auto">
            <div class="form-group">
              <label for="name">Middle Name:*</label>
              <input class="form-control" type="text" id="name" name="name">
            </div>
          </div>
          <div class="col-auto">
            <div class="form-group">
              <label for="name">Last Name:*</label>
              <input class="form-control" type="text" id="name" name="name">
            </div>
          </div>
          <div class="col-auto">
            <div class="form-group">
              <label for="email">Email Address:*</label>
              <input class="form-control" type="text" id="email" name="email">
            </div>
          </div>
          <!-- create a bootrap switch and ask if the person is External Examiner / Intercollegiate Examiner-->
          <div class="col-auto mt-2">
            <div class="form-group">
              <label for="name">Are you submitting a nomination for an External Examiner / Intercollegiate Examiner?:*</label>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" id="examinerYes" value="true" v-model="isExaminer">
                <label class="form-check-label" for="examinerYes">Yes</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" id="examinerNo" value="false" v-model="isExaminer">
                <label class="form-check-label" for="examinerNo">No</label>
              </div>
            </div>
          </div>
          <div class="col-auto" v-if="isExaminer">
            <div class="form-group">
              <label for="cv">Upload CV:</label>
              <input class="form-control" type="file" id="cv" name="cv">
            </div>
          </div>
          <div class="col-auto" v-if="isExaminer">
            <div class="form-group">
              <label for="nominationForm">Upload Nomination Form:</label>
              <input class="form-control" type="file" id="nominationForm" name="nominationForm">
            </div>
          </div>
          <div class="col-auto" v-if="isExaminer">
            <div class="form-group">
              <label for="email">Comments for resubmission only (optional):</label>
              <textarea class="form-control" id="comments" name="comments"></textarea>
            </div>
          </div>
          <div class="text-end"> <!-- Add the 'v-if' directive here -->
            <button class="btn btn-primary mt-4" @click="submitForm">Submit</button>
          </div>
          <p class="bg-light p-2 m-2" v-if="isExaminer">
            <!--add info icon bootstrap 5-->
            <i class="bi bi-info-circle"></i>
            Before submitting an invitation for an EEIE please ensure you have read the examiner
            guidelines in the 'enclosures' section which <a
              href=" https://www.london.ac.uk/about/work-us/support-examiners" target="_blank">can be found here</a>.
          </p>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue';

export default {
  setup() {
    const isExaminerString = ref("false"); // Use a string ref to track the radio button value

    // Convert the string value to a boolean for use with v-if
    const isExaminer = computed({
      get: () => isExaminerString.value === "true",
      set: (newValue) => {
        isExaminerString.value = newValue.toString();
      },
    });

    const submitForm = () => {
      // Add your form submission logic here
      // This function is called when the Submit button is clicked
    };

    return {
      isExaminer,
      submitForm,
    };
  },
};
</script>
