<template>
    <div class="container mt-3">
        <h2 class="mb-3"><i class="bi bi-bell"></i> My Notifications</h2>
        <div class="timeline">
            <ul class="list-unstyled">
                <li v-for="notification in notificationsData" :key="notification.id"
                    class="timeline-item p-3 mb-3 rounded" :class="getBackgroundClass(notification.status)">
                    <h5 class="mt-2">{{ groupTitles[notification.group] }} - {{ notification.title }}</h5>
                    <p><i class="bi bi-calendar-event"></i> {{ notification.date }}</p>
                    <p>{{ notification.message }}</p>
                    <span class="badge" :class="getBadgeClass(notification.status)">{{ notification.status }}</span>
                    <button type="button" class="btn btn-warning btn-sm mx-1"
                        @click="removeNotification(notification.id)">Remove</button>
                </li>
            </ul>
            <button type="button" class="btn btn-danger mt-3" @click="clearAllNotifications">Clear All</button>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue';

export default {
    setup() {
        const notificationsData = ref([
            { id: 1, group: 'communications', title: 'Message from Hiring Manager', message: 'A new message has been received.', date: '2024-01-20', status: 'New' },
            { id: 2, group: 'appointments', title: 'Appointment Offered', message: 'You have a new appointment offer.', date: '2024-01-18', status: 'Pending' },
            { id: 3, group: 'compliance', title: 'Document Expiry Alert', message: 'Your compliance document is about to expire.', date: '2024-01-25', status: 'Urgent' },
        ]);

        const groupTitles = {
            communications: 'Communications',
            appointments: 'Appointments',
            compliance: 'Compliance Updates',
        };

        function getBadgeClass(status) {
            switch (status) {
                case 'New':
                    return 'bg-success';
                case 'Pending':
                    return 'bg-warning text-dark';
                case 'Urgent':
                    return 'bg-danger';
                default:
                    return 'bg-secondary';
            }
        }

        function getBackgroundClass(status) {
            switch (status) {
                case 'New':
                    return 'bg-primary1';
                case 'Pending':
                    return 'bg-warning1';
                case 'Urgent':
                    return 'bg-danger1';
                default:
                    return 'bg-light';
            }
        }

        function removeNotification(notificationId) {
            notificationsData.value = notificationsData.value.filter(notification => notification.id !== notificationId);
        }

        function clearAllNotifications() {
            notificationsData.value = [];
        }

        return {
            notificationsData,
            groupTitles,
            getBadgeClass,
            getBackgroundClass,
            removeNotification,
            clearAllNotifications,
        };
    },
};
</script>

<style>
.timeline .timeline-item {
    /* Removed the gradient background for a clearer, solid color representation */
    border-left: 4px solid; /* Optional: adds a colored border on the left */
    margin-bottom: 1rem;
    padding: 0.5rem;
    border-radius: 0.25rem;
}

/* Additional hover effect for interactivity, maintaining the background color */
.timeline .timeline-item:hover {
    background-color: #f7f7f7;
}

/* Optional: Adjust the border-left color based on the notification status for better visibility */
.bg-primary1  {
    border-color: #c2ddfab6; /* Adjust the color code based on your primary color */
    background-color: #c2ddfab6 !important;
}

.bg-warning1 {
    border-color: #fef1caaf; /* Adjust the color code based on your warning color */
    background-color: #fef1caaf !important;
}

.bg-danger1 {
    border-color: #f8b8bd97; /* Adjust the color code based on your danger color */
    background-color: #f8b8bd97 !important;
}
</style>
