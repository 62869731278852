<template>
    <div>Processing login...</div>
  </template>
  
  <script>
  import { useRouter } from 'vue-router';
  import { fetchAuthSession } from '@aws-amplify/auth';
  
  export default {
    name: 'OAuthCallback',
    async mounted() {
      const router = useRouter(); // Get the router instance from the composition API
      try {
        await new Promise(resolve => setTimeout(resolve, 1000));
  
        const session = await fetchAuthSession();
        if (session && session.tokens) {
          const payload = session.tokens.accessToken.payload;
  
          // Extract username and sub
          const username = payload.username;
          const sub = payload.sub;
          const firstName = payload.given_name;
          const lastName = payload.family_name;
  
          // Store in localStorage
          localStorage.setItem('username', username);
          localStorage.setItem('firstName', firstName);
          localStorage.setItem('lastName', lastName);
          localStorage.setItem('sub', sub);
          localStorage.setItem('checkTime', new Date().getTime().toString());
    
        } else {
          throw new Error('Session tokens are undefined');
        }
        router.push('/manager/'); // Redirect to a protected area after login
      } catch (error) {
        console.error("Authentication failed:", error);
        router.push('/login'); // Redirect to login
      }
    }
  };
  </script>
  