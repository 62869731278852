<template>
  <div class="container mt-2">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">{{ formattedViewName }} for {{ selectedProgramme?.name }}
          <a @click="openModal" role="button"><i class="bi bi-pencil"></i></a>
        </h4>

        <form action="/assignment/save" method="post" enctype="multipart/form-data" id="assignment_form" class="form">
          <input type="hidden" name="viewName" value="direct">
          <!-- ... other hidden inputs ... -->

          <div class="mb-3">
            <label for="templateId" class="form-label">Template</label>
            <select id="templateId" name="templateId" class="form-select">
              <option value="">Select Template</option>
              <!-- ... other options ... -->
            </select>
          </div>

          <div class="card">
            <div class="card-header">
              Enter Last Name
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-6">
                  <div class="mb-3">
                    <label for="workerName">Please Select Academic Contractor:*</label>
                    <div class="form-group">
                      <input type="text" class="form-control" id="workerName" name="workerName" v-model="contractor.name"
                        v-on:input="filterContractors" autocomplete="off">
                      <div v-if="filteredContractors.length > 0" class="dropdown-menu show">
                        <a class="dropdown-item" v-for="contractor in filteredContractors" :key="contractor.id"
                          @click="selectContractor(contractor)">
                          {{ contractor.name }} ({{ contractor.id }})
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="card mb-3">
                    <div class="row g-0">
                      <div class="col-md-5">
                        <img v-if="contractor" :src="contractor.profilePic" alt="Profile Picture"
                          class="img-fluid rounded-start text-center" style="width: 100%;">
                      </div>
                      <div class="col-md-7">
                        <div class="card-body">
                          <h5 class="card-title">Compliance Checks</h5>
                          <ul class="list-group list-group-flush">
                            <li class="list-group-item d-flex justify-content-between align-items-center">
                              Identity
                              <i class="bi"
                                :class="[contractor?.isCompliant === 'TRUE' ? 'bi-check-circle-fill text-success' : 'bi-x-circle-fill text-danger']"></i>
                            </li>
                            <li class="list-group-item d-flex justify-content-between align-items-center">
                              Eligibility
                              <i class="bi"
                                :class="[contractor?.isCompliant === 'TRUE' ? 'bi-check-circle-fill text-success' : 'bi-x-circle-fill text-danger']"></i>
                            </li>
                            <li class="list-group-item d-flex justify-content-between align-items-center">
                              Working Hours Compliance
                              <i class="bi"
                                :class="[contractor?.isCompliant === 'TRUE' ? 'bi-check-circle-fill text-success' : 'bi-x-circle-fill text-danger']"></i>
                            </li>
                            <li class="list-group-item d-flex justify-content-between align-items-center">
                              Maximum hours / week
                              <span class="text-muted">{{ contractor.hours }}</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card mt-2">
            <div class="card-header">
              Assignment
            </div>
            <div class="card-body">
              <div class="form-group">
                <label for="title">Job Title:*</label>
                <select class="form-control" id="title" name="title" v-model="title">
                  <option selected>Choose...</option>
                  <option v-for="title in selectedProgramme?.titles" :key="title" :value="title">
                    {{ title }}
                  </option>
                </select>
              </div>
              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <label for="addField1">Programme / Module Code:</label>
                    <select class="form-control" id="budgetCodes" name="budgetCodes" v-model="selectedBudgetCode">
                      <option selected>Choose...</option>
                      <option v-for="budgetCode in selectedProgramme?.budgetCodes" :key="budgetCode.ModuleCode"
                        :value="budgetCode.ModuleCode">
                        {{ budgetCode.ModuleCode }} - {{ budgetCode.ModuleName }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col">
                  <label for="location">Location:</label>
                  <select class="form-select" id="location.id" name="location">
                    <option value="Birkbeck Main Campus" selected="selected">Birkbeck Main Campus</option>
                    <!-- Add more options here -->
                  </select>
                </div>
                <!-- <div class="col-md-6">
                  <div class="form-group">
                    <label for="addField2">Programme/ Module Name:</label>
                    <input type="text" class="form-control" id="addField2" name="addField2" value="">
                  </div>
                </div> -->
              </div>

              <div class="row">
                <div class="col-md-3">
                  <label for="startDate">Start Date:</label>
                  <input type="date" class="form-control" id="startDate" name="startDate" value="">
                </div>
                <div class="col-md-3">
                  <label for="endDate">Expected End Date:</label>
                  <input type="date" class="form-control" id="endDate" name="endDate" value="">
                </div>
                <div class="col-md-3">
                  <label for="respondBy">Academic Contractor Respond By:</label>
                  <input type="date" class="form-control" id="respondBy" name="respondBy" :value="fourWeeksFromNow">
                </div>
              </div>
              <input type="hidden" name="type" value="Temporary">
            </div>
          </div>

          <div class="card mt-2" v-if="selectedProgramme && selectedProgramme !== undefined">
            <div class="card-header">
              Programme Details for {{ selectedProgramme.name }}
            </div>
            <div class="card-body">
              <div class="accordion" id="feeTypesAccordion">
                <div v-for="(feeType, index) in selectedProgramme.feeTypes" :key="feeType.id" class="accordion-item">
                  <h2 class="accordion-header" :id="'heading' + feeType.id">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      :data-bs-target="'#collapse' + feeType.id" aria-expanded="false"
                      :aria-controls="'collapse' + feeType.id">
                      {{ feeType.name }}
                    </button>
                  </h2>
                  <div :id="'collapse' + feeType.id"
                    v-bind:class="{ 'accordion-collapse collapse': true, show: index === 0 }" aria-labelledby="headingOne"
                    data-bs-parent="#feeTypesAccordion">
                    <div class="accordion-body">
                      <!-- Insert fee type details here -->
                      <div class="row mb-3" v-if="feeType.showAll !== true">
                        <div class="col-md-7">
                          <div class="input-group mb-3">
                            <span class="input-group-text">Fee Type</span>
                            <select class="form-select" id="feeTypeSelect" name="feeTypeSelect" v-model="selectedFee">
                              <option selected>Choose...</option>
                              <option v-for="{ name, value } in feeType.fees" :key="name" :value="value">
                                {{ name }}
                              </option>
                            </select>
                            <span class="input-group-text" id="basic-addon2">&pound;{{ selectedFee }}</span>
                          </div>
                        </div>
                        <div class="col-md-5">
                          <div class="input-group mb-3">
                            <span class="input-group-text">Payment Schedule</span>
                            <select class="form-select" id="paymentScheduleSelect" name="paymentScheduleSelect">
                              <option selected>Choose...</option>
                              <option v-for="paymentSchedule in selectedProgramme.paymentSchedules" :key="paymentSchedule">
                                {{ paymentSchedule }}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div class="mb-3 row" v-else>
                        <div v-for="fee in feeType.fees" :key="fee.name">
                          <div class="col">
                            <div class="input-group mb-3">
                              <div class="input-group-prepend">
                                <span class="input-group-text" id="basic-addon1">{{ fee.name }}</span>
                              </div>
                              <input type="number" min="0" class="form-control" name="feeSlot" placeholder="Enter Value"
                                v-model.number="fee.slotValue" aria-label="slot" aria-describedby="basic-addon1">
                              <span class="input-group-text" id="basic-addon2">&pound;{{ calculateTotal(fee.value,
                                fee.slotValue) }} @ &pound;{{ fee.value }} / unit</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card mt-2">
            <div class="card-header">
              Timesheet Administrators
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <div class="mb-3">
                    <label for="tsa1" class="form-label">Admin 1</label>
                    <select class="form-select" id="tsa1" name="tsa1">
                      <option selected>Choose...</option>
                      <option v-for="manager in managers" :key="manager.id">
                        {{ manager.firstName }} {{ manager.lastName }} ({{ manager.department }})
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col">
                  <div class="mb-3">
                    <label for="tsa2" class="form-label">Admin 2</label>
                    <select class="form-select" id="tsa2" name="tsa2">
                      <option selected>Choose...</option>
                      <option v-for="manager in managers" :key="manager.id">
                        {{ manager.firstName }} {{ manager.lastName }} ({{ manager.department }})
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card mt-2">
            <div class="card-header">
              Budget Code Details
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col mb-3">
                  <label for="accountCode" class="form-label">Account Code</label>
                  <input type="text" class="form-control" id="accountCode" name="accountCode" readonly="readonly" value="695000">
                </div>
                <div class="col mb-3">
                  <label for="costCentre" class="form-label">Cost Centre</label>
                  <select class="form-select" id="costCentre" name="costCentre">
                    <option selected>Choose...</option>
                    <option v-for="costCentre in selectedProgramme?.costCentres" :key="costCentre" :value="costCentre">
                      {{ costCentre }}
                    </option>
                  </select>
                </div>
                <div class="col mb-3">
                  <label for="projectCode" class="form-label">Project</label>
                  <select class="form-select" id="projectCode" name="projectCode" >
                    <option selected>Choose...</option>
                    <option v-for="projectCode in selectedProgramme?.projectCodes" :key="projectCode"
                      :value="projectCode">
                      {{ projectCode }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="row">
                <div class="col mb-3">
                  <label for="productCode" class="form-label">Product</label>
                  <input type="text" class="form-control" id="productCode" name="productCode" pattern="[A-Za-z]{1}\d{6}">
                </div>
                <div class="col mb-3">
                  <label for="invoicingContact" class="form-label">Invoicing Contact</label>
                  <select class="form-select" id="invoicingContact" name="invoicingContact">
                    <option selected>Choose...</option>
                    <option v-for="manager in managers" :key="manager.id">
                      {{ manager.firstName }} {{ manager.lastName }} ({{ manager.department }})
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <!-- NOTES Card -->
          <div class="card mt-2">
            <div class="card-header">
              Notes
            </div>
            <div class="card-body">
              <div class="mb-3">
                <label for="notesForCandidate" class="form-label">Notes for Academic Contractor (These notes are emailed to
                  candidate)</label>
                <textarea class="form-control" id="notesForCandidate" name="notesForCandidate"></textarea>
              </div>
              <div class="mb-3">
                <label for="internalNotes" class="form-label">Internal Notes (These notes are not emailed to
                  candidate)</label>
                <textarea class="form-control" id="internalNotes" name="internalNotes"></textarea>
              </div>
            </div>
          </div>

          <!-- ASSIGNMENT DETAILS Card -->
          <div class="card mt-2">
            <div class="card-header">
              Assignment Details
            </div>
            <div class="card-body">
              <div class="mb-3">
                <label for="jobDescription" class="form-label">Job Description</label>
                <textarea class="form-control" id="jobDescription" name="jobDescription"></textarea>
              </div>
              <div class="mb-3">
                <label for="skillsRequired" class="form-label">Skills Required</label>
                <textarea class="form-control" id="skillsRequired" name="skillsRequired"></textarea>
              </div>
              <div class="mb-3">
                <label for="qualifications" class="form-label">Qualifications</label>
                <textarea class="form-control" id="qualifications" name="qualifications"></textarea>
              </div>
              <div class="mb-3">
                <label for="healthSafetyIssues" class="form-label">Health & Safety Issues</label>
                <textarea class="form-control" id="healthSafetyIssues" name="healthSafetyIssues"></textarea>
              </div>
            </div>
          </div>

          <!-- ASSIGNMENT ATTACHMENTS Card -->
          <div class="card mt-2">
            <div class="card-header">
              Assignment Attachments
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col mb-3">
                  <label for="fileUpload" class="form-label">File Upload</label>
                  <input class="form-control" type="file" id="fileUpload">
                </div>
                <div class="col mb-3">
                  <div class="form-check form-switch mt-4">
                    <input class="form-check-input" type="checkbox" id="visibleToCandidate">
                    <label class="form-check-label" for="visibleToCandidate">Visible To Academic Contractor</label>
                  </div>
                </div>
                <div class="col mb-3">
                  <div class="form-check form-switch mt-4">
                    <input class="form-check-input" type="checkbox" id="candidateMustSign">
                    <label class="form-check-label" for="candidateMustSign">Academic Contractor Must Sign</label>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col mb-3">
                  <label for="fileUpload" class="form-label">File Upload</label>
                  <input class="form-control" type="file" id="fileUpload">
                </div>
                <div class="col mb-3">
                  <div class="form-check form-switch mt-4">
                    <input class="form-check-input" type="checkbox" id="visibleToCandidate">
                    <label class="form-check-label" for="visibleToCandidate">Visible To Academic Contractor</label>
                  </div>
                </div>
                <div class="col mb-3">
                  <div class="form-check form-switch mt-4">
                    <input class="form-check-input" type="checkbox" id="candidateMustSign">
                    <label class="form-check-label" for="candidateMustSign">Academic Contractor Must Sign</label>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col mb-3">
                  <label for="fileUpload" class="form-label">File Upload</label>
                  <input class="form-control" type="file" id="fileUpload">
                </div>
                <div class="col mb-3">
                  <div class="form-check form-switch mt-4">
                    <input class="form-check-input" type="checkbox" id="visibleToCandidate">
                    <label class="form-check-label" for="visibleToCandidate">Visible To Academic Contractor</label>
                  </div>
                </div>
                <div class="col mb-3">
                  <div class="form-check form-switch mt-4">
                    <input class="form-check-input" type="checkbox" id="candidateMustSign">
                    <label class="form-check-label" for="candidateMustSign">Academic Contractor Must Sign</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-center mt-3 mb-5">
            <div class="btn-group" role="group" aria-label="Button group">
              <button type="submit" class="btn btn-outline-danger">Withdraw Offer</button>
              <button type="submit" class="btn btn-outline-warning">End Programme</button>
              <button type="submit" class="btn btn-outline-success">Edit Programme</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- Modal -->
  <div class="modal fade" id="selectProgrammeModal" tabindex="-1" aria-labelledby="selectProgrammeModalLabel"
    aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="selectProgrammeModalLabel">Select Programme</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <!-- Dropdown to select a program -->
          <select class="form-select" id="jobFamily_idModal" name="jobFamily_idModal" required
            v-model="selectedProgrammeId">
            <option value=""></option>
            <option v-for="programme in selectedProgrammes" :value="programme.id" :key="programme.id">{{
              programme.name }}</option>
          </select>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary" @click="applyProgramme">Apply</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted, watchEffect } from 'vue';
import { useRoute } from 'vue-router';
import contractorData from '../../mock/contractors.json';
import programmeData from '../../mock/programmes.json';
import savedProgrammes from '../../mock/programmeFeed.json';
import managers from '../../mock/managers.json';
import { Modal } from 'bootstrap';

export default {
  setup() {
    const route = useRoute();
    const formattedViewName = computed(() => {
      return route.name.replace(/([-A-Z])/g, ' $1').trim();
    });
    const contractorId = parseInt(route.params.id, 10);
    const contractor = ref(contractorData.find(c => c.id === contractorId));
    const selectedContractor = ref(contractorData.find(c => c.id === contractorId));
    const selectedProgrammeId = ref(null);
    const selectedProgramme = ref(null);
    const savedProgramme = ref(null);
    const filteredContractors = ref([]);
    const selectedFee = ref('');
    const slot = ref(0);

    watchEffect(() => {
        savedProgramme.value = savedProgrammes.find(p => p.id === parseInt(route.query.programme, 10));    
    });

    const openModal = () => {
      const modalElement = document.getElementById('selectProgrammeModal');
      if (modalElement) {
        const programmeModal = new Modal(modalElement);
        programmeModal.show();
      }
    };

    const fetchProgramme = () => {
      const hash = window.location.hash;
      const match = hash.match(/programme=(\d+)/);
      if (match) {
        const programmeId = parseInt(match[1], 10);
        if (!isNaN(programmeId)) {
          // Find the member that contains the programme
          const member = programmeData.find(m => m.programmes.some(p => p.id === programmeId));
          if (member) {
            // Find the programme within the member's programmes
            const programme = member.programmes.find(p => p.id === programmeId);
            selectedProgramme.value = programme;
            selectedProgrammeId.value = programme.id;
          }
        }
      }
    };

    const selectedProgrammes = computed(() => {
      const orgId = contractor.value.orgId; // Assuming contractor.orgId is the correct property
      const member = programmeData.find(member => member.member_id === orgId);
      return member ? member.programmes : [];
    });

    const fourWeeksFromNow = computed(() => {
      const date = new Date();
      date.setDate(date.getDate() + 28); // Add 4 weeks
      return date.toISOString().split('T')[0]; // Format as YYYY-MM-DD
    });

    onMounted(() => {
      if (!window.location.hash.includes('programme')) {
        openModal();
      } else {
        fetchProgramme();
      }
    });

    const applyProgramme = () => {
      const selectedValue = document.getElementById('jobFamily_idModal').value;
      const orgId = contractor.value.orgId;

      // Find the member with the specific orgId
      const member = programmeData.find(member => member.member_id === orgId);

      if (member) {
        // Find the programme within the member's programmes array
        const programme = member.programmes.find(p => p.id === parseInt(selectedValue, 10));

        if (programme) {
          selectedProgramme.value = programme;

          // Update the URL fragment with the selected program ID
          const newURL = `#programme=${programme.id}`;
          window.location.hash = newURL;

          const modalElement = document.getElementById('selectProgrammeModal');
          if (modalElement) {
            const programmeModal = Modal.getInstance(modalElement);
            console.log(programmeModal);
            programmeModal.hide();
          } else {
            console.log("modalElement is null");
          }
        }
      }
    };

    const filterContractors = () => {
      filteredContractors.value = contractorData.filter(contractor => contractor.name.includes(selectedContractor.value));
    };

    const selectContractor = (contractor) => {
      selectedContractor.value = contractor.name;
      filteredContractors.value = []; // Clear the dropdown
    };

    const calculateTotal = (feeValue, slotValue) => {
      slotValue = slotValue || 0;
      return Math.round(feeValue * slotValue * 100) / 100;
    }

    return {
      formattedViewName,
      contractor,
      selectedProgramme,
      selectedProgrammes,
      selectedProgrammeId,
      openModal,
      applyProgramme,
      managers,
      contractorData,
      selectContractor,
      filterContractors,
      filteredContractors,
      selectedContractor,
      selectedFee,
      calculateTotal,
      slot,
      fourWeeksFromNow
    };
  }
};
</script>


