/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_5X70XY0rX",
    "aws_user_pools_web_client_id": "6423ehmpqu3lrnafphs1j8i35j",
    "oauth": {
        "domain": "auth.uolacademiccontractors.com",
        "scope": [
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:8080/oauth/callback,https://talentbank.technology/oauth/callback,https://test.uolacademiccontractors.com/success.html",
        "redirectSignOut": "https://localhost:8080/auth",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;