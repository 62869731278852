<template>
    <div class="container mt-3">
        <h2 class="mb-3"><i class="bi bi-cash-stack"></i> My Payments</h2>

        <!-- Bootstrap 5 Tab Navigation -->
        <ul class="nav nav-tabs" id="paymentTabs" role="tablist">
            <li class="nav-item" role="presentation">
                <button class="nav-link active" id="upcoming-tab" data-bs-toggle="tab" data-bs-target="#upcoming"
                    type="button" role="tab" aria-controls="upcoming" aria-selected="true"><i class="bi bi-clock"></i>
                    Upcoming</button>
            </li>
            <li class="nav-item" role="presentation">
                <button class="nav-link" id="processed-tab" data-bs-toggle="tab" data-bs-target="#processed"
                    type="button" role="tab" aria-controls="processed" aria-selected="false"><i
                        class="bi bi-check-circle"></i> Processed</button>
            </li>
        </ul>

        <!-- Tab Content -->
        <div class="tab-content" id="paymentTabsContent">
            <!-- Upcoming Payments Tab Pane -->
            <div class="tab-pane fade show active" id="upcoming" role="tabpanel" aria-labelledby="upcoming-tab">
                <div v-for="(payment, index) in upcomingPayments" :key="index" class="card mb-3">
                    <div class="card-body">
                        <h5 class="card-title"><i class="bi bi-wallet2"></i> Upcoming Payment Details</h5>
                        <p class="card-text"><i class="bi bi-currency-exchange"></i> Amount: £{{ payment.amount }}</p>
                        <p class="card-text"><i class="bi bi-calendar-event"></i> Expected Date: {{ payment.date }}</p>
                    </div>
                </div>
            </div>

            <!-- Processed Payments Tab Pane -->
            <div class="tab-pane fade" id="processed" role="tabpanel" aria-labelledby="processed-tab">
                <div v-for="(payments, appointmentName) in groupedPayments" :key="appointmentName">
                    <div v-for="payment in payments" :key="payment.date" class="card mb-3">
                        <div class="card-body">
                            <h5 class="card-title"><i class="bi bi-wallet2"></i> Processed Payment Details</h5>
                            <p class="card-text"><i class="bi bi-currency-exchange"></i> Amount: £{{ payment.amount }}
                            </p>
                            <p class="card-text"><i class="bi bi-calendar-check"></i> Date: {{ payment.date }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Bank Details Section -->
        <div class="mt-4">
            <h3><i class="bi bi-bank2"></i> Bank Details</h3>
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">Bank Details for Prof David Smith</h5>
                    <!-- Non-editable view -->
                    <div v-if="!editMode">
                        <p><strong>Name on Account:</strong> {{ bankDetails.accountName }}</p>
                        <p><strong>Account Number:</strong> {{ bankDetails.accountNumber }}</p>
                        <p><strong>Sort Code:</strong> {{ bankDetails.sortCode }}</p>
                        <button class="btn btn-secondary" @click="editMode = true">Edit</button>
                    </div>
                    <!-- Editable view -->
                    <form v-else @submit.prevent="saveBankDetails">
                        <div class="mb-3">
                            <label for="accountName" class="form-label">Name on Account</label>
                            <input type="text" id="accountName" v-model="editableBankDetails.accountName"
                                class="form-control" required>
                        </div>
                        <div class="mb-3">
                            <label for="accountNumber" class="form-label">Account Number</label>
                            <input type="text" id="accountNumber" v-model="editableBankDetails.accountNumber"
                                class="form-control" required>
                        </div>
                        <div class="mb-3">
                            <label for="sortCode" class="form-label">Sort Code</label>
                            <input type="text" id="sortCode" v-model="editableBankDetails.sortCode" class="form-control"
                                required>
                        </div>
                        <button type="submit" class="btn btn-sm mx-1 btn-primary">Save</button>
                        <button type="button" class="btn btn-sm mx-1 btn-secondary" @click="cancelEdit">Cancel</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, computed } from 'vue';

export default {
    setup() {
        const paymentsData = ref([
            // Processed payments
            {
                appointmentName: 'MSc Computer Science - Module Leader',
                amount: '4058.36',
                date: '2024-01-15',
                status: 'processed',
            },
            {
                appointmentName: 'MSc Computer Science - Module Leader',
                amount: '4058.36',
                date: '2023-10-15',
                status: 'processed',
            },
            // More processed payments...
        ]);
        const bankDetails = ref({
            accountName: 'Prof David Smith',
            accountNumber: 'XXXXXX1234',
            sortCode: '60-70-80',
        });

        const editMode = ref(false);
        const editableBankDetails = ref({ ...bankDetails.value });

        function saveBankDetails() {
            // Logic to save the amended bank details
            bankDetails.value = { ...editableBankDetails.value };
            editMode.value = false;
            alert('Bank details saved successfully.');
        }

        function cancelEdit() {
            editableBankDetails.value = { ...bankDetails.value };
            editMode.value = false;
        }


        const upcomingPayments = ref([
            // Upcoming payments
            {
                amount: '4058.36',
                date: '2024-04-15',
                status: 'upcoming',
            },
            // More upcoming payments...
        ]);

        const groupedPayments = computed(() => {
            return paymentsData.value.reduce((acc, payment) => {
                if (payment.status === 'processed') {
                    if (!acc[payment.appointmentName]) {
                        acc[payment.appointmentName] = [];
                    }
                    acc[payment.appointmentName].push(payment);
                }
                return acc;
            }, {});
        });

        return {
            upcomingPayments,
            groupedPayments,
            bankDetails,
            editMode,
            editableBankDetails,
            saveBankDetails,
            cancelEdit,
        };
    }
};
</script>