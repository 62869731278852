/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const contractorRegisterStep1Input = /* GraphQL */ `
  mutation ContractorRegisterStep1Input($input: ContractorRegisterStep1Input!) {
    contractorRegisterStep1Input(input: $input) {
      id
      title
      firstName
      middleName
      lastName
      knownAs
      address1
      address2
      town
      county
      postcode
      phone
      mobile
      dob
      nino
      nationality
      livingInUK
      email
      employmentStatus
      studentVisa
      agreeTerms
      taxCode
      gender
      hasGradLoan
      graduated
      loanPlan
      hasPostgradLoan
      repayingPostgradLoan
      repayingDirectly
      accountName
      bankName
      sortCode
      accountNumber
      rollNumber
      iban
      swiftBic
      routingNumber
      __typename
    }
  }
`;
export const contractorRegisterStep2Input = /* GraphQL */ `
  mutation ContractorRegisterStep2Input(
    $id: ID!
    $input: ContractorRegisterStep2Input!
  ) {
    contractorRegisterStep2Input(id: $id, input: $input) {
      id
      title
      firstName
      middleName
      lastName
      knownAs
      address1
      address2
      town
      county
      postcode
      phone
      mobile
      dob
      nino
      nationality
      livingInUK
      email
      employmentStatus
      studentVisa
      agreeTerms
      taxCode
      gender
      hasGradLoan
      graduated
      loanPlan
      hasPostgradLoan
      repayingPostgradLoan
      repayingDirectly
      accountName
      bankName
      sortCode
      accountNumber
      rollNumber
      iban
      swiftBic
      routingNumber
      __typename
    }
  }
`;
export const deleteAcademicContractor = /* GraphQL */ `
  mutation DeleteAcademicContractor($input: DeleteAcademicContractorInput!) {
    deleteAcademicContractor(input: $input) {
      id
      title
      firstName
      middleName
      lastName
      knownAs
      address1
      address2
      town
      county
      postcode
      phone
      mobile
      dob
      nino
      nationality
      livingInUK
      email
      employmentStatus
      studentVisa
      agreeTerms
      taxCode
      gender
      hasGradLoan
      graduated
      loanPlan
      hasPostgradLoan
      repayingPostgradLoan
      repayingDirectly
      accountName
      bankName
      sortCode
      accountNumber
      rollNumber
      iban
      swiftBic
      routingNumber
      __typename
    }
  }
`;
export const getAcademicContractor = /* GraphQL */ `
  mutation GetAcademicContractor($input: GetAcademicContractorInput!) {
    getAcademicContractor(input: $input) {
      id
      title
      firstName
      middleName
      lastName
      knownAs
      address1
      address2
      town
      county
      postcode
      phone
      mobile
      dob
      nino
      nationality
      livingInUK
      email
      employmentStatus
      studentVisa
      agreeTerms
      taxCode
      gender
      hasGradLoan
      graduated
      loanPlan
      hasPostgradLoan
      repayingPostgradLoan
      repayingDirectly
      accountName
      bankName
      sortCode
      accountNumber
      rollNumber
      iban
      swiftBic
      routingNumber
      __typename
    }
  }
`;
