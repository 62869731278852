<template>
    <div class="container mt-3">
        <!-- Offered Appointments Section -->
        <div v-if="offeredAppointments.length" class="offered-appointments mb-5">
            <h2 class="mb-3"><i class="bi bi-calendar-plus"></i> Offered Appointments</h2>
            <div class="card mb-3" v-for="appointment in offeredAppointments" :key="appointment.id">
                <div class="card-body">
                    <h5 class="card-title"><i class="bi bi-bookmark-star"></i> {{ appointment.name }}</h5>
                    <p><i class="bi bi-geo-alt"></i> Federation Member: {{ appointment.university }}</p>
                    <p><i class="bi bi-currency-exchange"></i> Payments: £{{ appointment.payments }}</p>
                    <p>
                        <i class="bi bi-check2-square"></i>
                        Contract Accepted:
                        <input type="checkbox" :checked="appointment.contractAccepted" disabled>
                    </p>
                    <!-- Accept and Decline Buttons -->
                    <button type="button" class="btn btn-sm mx-1 btn-success" data-bs-toggle="modal"
                        :data-bs-target="'#appointmentModal-' + appointment.id">Review & Accept</button>

                    <button type="button" class="btn btn-sm btn-danger"
                        @click="declineAppointment(appointment.id)">Decline</button>
                </div>
            </div>
        </div>


        <!-- Current Appointments Section -->
        <div class="current-appointments">
            <h2 class="mb-3"><i class="bi bi-calendar-check"></i> Current Appointments</h2>
            <div class="card" v-for="appointment in currentAppointments" :key="appointment.id">
                <div class="card-body">
                    <h5 class="card-title"><i class="bi bi-bookmark-check"></i> {{ appointment.name }}</h5>
                    <p><i class="bi bi-geo-alt"></i> Federation Member: {{ appointment.university }}</p>
                    <p><i class="bi bi-currency-exchange"></i> Payments: £{{ appointment.payments }}</p>
                    <p>
                        <i class="bi bi-check2-square"></i>
                        Contract Accepted:
                        <input type="checkbox" :checked="appointment.contractAccepted" disabled>
                    </p>
                </div>
            </div>
        </div>
    </div>
    <!-- Appointment Modals for each offered appointment -->
    <div v-for="appointment in offeredAppointments" :key="'modal-' + appointment.id" class="modal fade"
        :id="'appointmentModal-' + appointment.id" tabindex="-1" aria-labelledby="appointmentModalLabel"
        aria-hidden="true">

        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="appointmentModalLabel">Appointment Details - {{ appointment.name }}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <!-- Inserted Work Plan Example Here -->
                    <h6>Work Plan for {{ appointment.workPlan.id }} - {{ appointment.workPlan.title }}</h6>
                    <p>Line Manager(s): {{ appointment.workPlan.lineManager }}</p>
                    <p>Pay Summary:</p>
                    <table class="table table-striped text-center">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Classroom</th>
                                <th>Lab</th>
                                <th>Business Requirements</th>
                                <th>Marking</th>
                                <th>Training</th>
                                <th>Total Hours</th>
                                <th>Pay</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1</td>
                                <td>{{ appointment.workPlan.paySummary.classroom }}</td>
                                <td>{{ appointment.workPlan.paySummary.lab }}</td>
                                <td>{{ appointment.workPlan.paySummary.businessRequirements }}</td>
                                <td>{{ appointment.workPlan.paySummary.marking }}</td>
                                <td>{{ appointment.workPlan.paySummary.training }}</td>
                                <td>{{ appointment.workPlan.paySummary.totalHours }}</td>
                                <td>{{ appointment.workPlan.paySummary.pay }}</td>
                            </tr>
                        </tbody>
                    </table>
                    <!-- More detailed work plan information can be added here -->
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="button" class="btn btn-primary"
                        @click="acceptAppointment(appointment.id)">Accept</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue';

export default {
    setup() {
        const offeredAppointments = ref([
            {
                id: 1,
                name: 'MSc Computer Science - Online Tutor Offer',
                university: 'City University',
                payments: '1200.00',
                contractAccepted: false,
                workPlan: {
                    id: 'DTA-12345',
                    title: 'Online Tutor Offer',
                    lineManager: 'George Green',
                    paySummary: {
                        classroom: 2,
                        lab: 0,
                        businessRequirements: 0,
                        marking: 0,
                        training: 0,
                        totalHours: 2,
                        pay: 40.92
                    },
                    details: {
                        classroomBasedTeaching: {
                            scenario: 'My Workplan Scenario',
                            sessionDuration: 1.0,
                            sessionsPerGroup: 1.0,
                            noOfGroups: 1,
                            totalSessionHours: 1.0,
                            totalPrepHours: 1.0,
                            totalFeedbackHours: 0,
                            totalHoursPerSession: 2,
                            payPerSession: 40.92,
                            totalHours: 2.0,
                            grossPay: 40.92
                        },
                        // You can add more detailed sections like 'classroomBasedExtras', 'labBasedTeaching', etc.
                    }
                }
            },
            // More offered appointments can be added here with their respective work plans
        ]);

        const currentAppointments = ref([
            // Current appointments data
            {
                id: 2,
                name: 'BSc Information Technology - Seminar Leader',
                university: 'Birbeck University of London',
                payments: '3058.75',
                contractAccepted: true,
                // Workplan details for current appointments can also be added similar to offered appointments
            }
        ]);

        function acceptAppointment(id) {
            // Logic to accept the appointment
            console.log('Appointment accepted:', id);
            // Here you would typically trigger a backend update to reflect the acceptance of the appointment
        }

        function declineAppointment(id) {
            // Logic to decline the appointment
            console.log('Appointment declined:', id);
            // Here you would typically trigger a backend update to reflect the decline of the appointment
        }

        return {
            offeredAppointments,
            currentAppointments,
            acceptAppointment,
            declineAppointment,
        };
    }
};
</script>


<style>
.offered-appointments {
    background-color: #f8d7da;
    border-left: 5px solid #f5c2c7;
    padding: 20px;
}

.current-appointments {
    padding: 20px;
}
</style>