import { createApp } from 'vue';
import App from './App.vue';
import { Amplify } from 'aws-amplify';
import awsconfig from './aws-exports';
import router from './router';
import 'bootstrap-icons/font/bootstrap-icons.css';
import store from './store';

Amplify.configure(awsconfig);

const app = createApp(App);
app.use(store); // Use the Vuex store
app.use(router);
app.mount('#app');
