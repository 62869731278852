<template>
  <div class="container mt-2">
    <div class="accordion" id="accordionPanelsStayOpenExample">
      <div class="accordion-item">
        <h2 class="accordion-header" id="panelsStayOpen-headingOne">
          <button v-if="userType === 'C'"  class="accordion-button" type="button" data-bs-toggle="collapse"
            data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true"
            aria-controls="panelsStayOpen-collapseOne">
            Consultant Home
          </button>
          <button v-else class="accordion-button" type="button" data-bs-toggle="collapse"
            data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true"
            aria-controls="panelsStayOpen-collapseOne">
            Manager Home
          </button>
    </h2>
    <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingOne">
      <div class="accordion-body">
            <div class="row mt-4">
              <div class="col">
                <div class="chart-container">
                  <canvas id="contractorsChart"></canvas>
                </div>
              </div>
              <div class="col stats">
                <p class="card-text">Upcoming Payroll Date: {{ upcomingPayrollDate }}</p>
                <p class="card-text">Total Managers: 10</p>
                <p class="card-text">New Contractors: 5</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Manager Contact Details Section -->
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingTwo">
          <button v-if="userType === 'C'" class="accordion-button collapsed" type="button" @click="toggleSection('contact')"
            :class="{ 'collapsed': activeSection !== 'contact' }" data-bs-toggle="collapse"
            data-bs-target="#collapseTwo" :aria-expanded="activeSection === 'contact'" aria-controls="collapseTwo">
            Consultant Contact Details
          </button>
          <button v-else class="accordion-button collapsed" type="button" @click="toggleSection('contact')"
            :class="{ 'collapsed': activeSection !== 'contact' }" data-bs-toggle="collapse"
            data-bs-target="#collapseTwo" :aria-expanded="activeSection === 'contact'" aria-controls="collapseTwo">
            Manager Contact Details
          </button>
        </h2>

        <div id="collapseTwo" class="accordion-collapse collapse" :class="{ 'show': activeSection === 'contact' }"
          aria-labelledby="headingTwo">
          <div class="accordion-body">

            <!-- Contact Details Section -->
            <div class="contact-details-section mb-3">
              <h3>Contact Details</h3>

              <!-- Read-Only Fields -->
              <div class="form-group">
                <label>Federation Member:</label>
                <input type="text" class="form-control" value="University London" readonly>
              </div>

              <div class="form-group">
    <label for="department">Department</label>
    <select id="department" class="form-control">
        <option value="Academic Contractors Team">Academic Contractors Team</option>
        <option value="Fees Office">Fees Office</option>
        <option value="Worldwide">UoL Worldwide</option>
    </select>
</div>


              <div class="form-group">
                <label>Address:</label>
                <input type="text" class="form-control" value="123 London Street, London, UK" readonly>
              </div>

              <!-- Editable Fields -->
              <div class="form-group">
                <label>First Name:</label>
                <input type="text" class="form-control" v-model="firstName" placeholder="John">
              </div>

              <div class="form-group">
                <label>Last Name:</label>
                <input type="text" class="form-control" v-model="lastName" placeholder="Doe">
              </div>

              <div class="form-group">
                <label>Phone:</label>
                <input type="tel" class="form-control" v-model="phone" placeholder="+44 123 456 7890">
              </div>
            </div>

            <!-- Email Preferences Section -->
          </div>
        </div>
      </div>

    </div>
  </div>
</template>
<script>
import { ref, onMounted } from 'vue';
import Chart from 'chart.js/auto';

export default {
  setup() {
    // State for accordion panels
    const homeSection = ref(true);
    const contactSection = ref(false);
    const userType = ref(localStorage.getItem('userType') || 'M');

    // Data for contact details form
    const firstName = ref('John');
    const lastName = ref('Smith');
    const phone = ref('+44 123 456 7890');
    const timesheetEmail = ref('');
    const programmeEndEmail = ref('');

    // State for other data
    const upcomingPayrollDate = ref('');
    const formattedViewName = ref('Manager Contact Details');

    // Toggle function for accordion panels
    const toggleSection = (section) => {
      if (section === 'home') {
        homeSection.value = !homeSection.value;
        contactSection.value = false;
      } else if (section === 'contact') {
        contactSection.value = !contactSection.value;
        homeSection.value = false;
      }
    };

    // Function to calculate the next payroll date
    const calculateNextPayrollDate = () => {
      const today = new Date();
      const nextMonth = new Date(today.getFullYear(), today.getMonth() + 1, 1);
      upcomingPayrollDate.value = nextMonth.toDateString();
    };

    // Function to render the contractors chart
    const renderContractorsChart = () => {
      const ctx = document.getElementById('contractorsChart').getContext('2d');
      new Chart(ctx, {
        type: 'bar',
        data: {
          labels: ['Registered', 'Employed'],
          datasets: [{
            label: 'Number of Contractors',
            data: [12, 8], // Mock data
            backgroundColor: ['rgba(255, 99, 132, 0.2)', 'rgba(54, 162, 235, 0.2)'],
            borderColor: ['rgba(255, 99, 132, 1)', 'rgba(54, 162, 235, 1)'],
            borderWidth: 1
          }]
        },
        options: {
          scales: {
            y: {
              beginAtZero: true
            }
          }
        }
      });
    };

    // Mounted lifecycle hook to calculate and render initial data
    onMounted(() => {
      calculateNextPayrollDate();
      renderContractorsChart();
    });

    // Expose the state and methods to the template
    return {
      homeSection,
      contactSection,
      toggleSection,
      upcomingPayrollDate,
      firstName,
      lastName,
      phone,
      timesheetEmail,
      programmeEndEmail,
      formattedViewName,
      userType,
    };
  }
};
</script>
