<template>
    <div>Logging out...</div>
</template>

<script>
import { useRouter } from 'vue-router';
import { signOut } from '@aws-amplify/auth';

export default {
    name: 'LogOut',
    async mounted() {
        const router = useRouter(); // Get the router instance from the composition API
        try {
            await signOut(); // Sign out the user

            // Clear localStorage
            localStorage.removeItem('username');
            localStorage.removeItem('firstName');
            localStorage.removeItem('lastName');
            localStorage.removeItem('sub');
            localStorage.removeItem('checkTime');

            router.push('/login'); // Redirect to login
        } catch (error) {
            console.error("Logout failed:", error);
            router.push('/login'); // Redirect to login
        }
    }
};
</script>