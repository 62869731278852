import { createRouter, createWebHistory } from 'vue-router';
import LoginPage from '@/views/LoginPage.vue';
import ManagerHome from '@/views/manager/ManagerHome.vue';
import MyContactDetails from '@/views/manager/MyContactDetails.vue';
import MyProgramme from '@/views/manager/MyProgramme.vue';
import MyLocations from '@/views/manager/MyLocations.vue';
import InviteColleague from '@/views/manager/InviteColleague.vue';
import SearchContractors from '@/views/manager/SearchContractors.vue';
import ViewProfile from '@/views/manager/ViewProfile.vue';
import ListContractors from '@/views/manager/ListContractors.vue';
import ManageGroups from '@/views/manager/ManageGroups.vue';
import InviteContractors from '@/views/manager/InviteContractors.vue';
import ManageProgrammes from '@/views/manager/ManageProgrammes.vue';
import OfferAppointment from '@/views/manager/OfferAppointment.vue';
import EditProgramme from '@/views/manager/EditProgramme.vue';
import AssignmentTemplates from '@/views/manager/AssignmentTemplates.vue';
import HolidayRequests from '@/views/manager/HolidayRequests.vue';
import PendingTasks from '@/views/manager/PendingTasks.vue';
import TimesheetHistory from '@/views/manager/TimesheetHistory.vue';
import InvoiceHistory from '@/views/manager/InvoiceHistory.vue';
import ChangePassword from '@/views/manager/ChangePassword.vue';
import RegisterStep1 from '@/views/contractor/RegisterStep1.vue';
import RegisterStep2 from '@/views/contractor/RegisterStep2.vue';
import RegisterStep3 from '@/views/contractor/RegisterStep3.vue';
import LogoutPage from '@/views/LogoutPage.vue';
import OAuthCallback from '@/views/OAuthCallback.vue';
import MyProfile from '@/views/contractor/MyProfile.vue';
import ContractorContactDetails from '@/views/contractor/MyContactDetails.vue';
import MyCompliance from '@/views/contractor/MyCompliance.vue';
import ChecklistForAcademicContractors from '@/views/contractor/ChecklistForAcademicContractors.vue';
import MyPayments from '@/views/contractor/MyPayments.vue';
import MyAppointments from '@/views/contractor/MyAppointments.vue';
import MyNotifications from '@/views/contractor/MyNotifications.vue';
import HelpSupport from '@/views/contractor/HelpSupport.vue';
import ContractorChangePassword from '@/views/contractor/ChangePassword.vue';

import { getCurrentAuthenticatedUser } from '@/authService';

const routes = [
    {
        path: '/oauth/callback',
        name: 'OAuthCallback',
        component: OAuthCallback
    },
    {
        path: '/login',
        name: 'Login',
        component: LoginPage
    },
    {
        path: '/logout',
        name: 'Logout',
        component: LogoutPage
    },
    {
        path: '/manager/',
        name: 'ManagerHome',
        component: ManagerHome,
        meta: { layout: 'manager' }
    },
    {
        path: '/manager/my-contact-details',
        name: 'MyContactDetails',
        component: MyContactDetails,
        meta: { layout: 'manager' } 
    },
    {
        path: '/manager/my-programme',
        name: 'MyProgramme',
        component: MyProgramme,
        meta: { layout: 'manager' } 
    },
    {
        path: '/manager/my-locations',
        name: 'MyLocations',
        component: MyLocations,
        meta: { layout: 'manager' } 
    },
    {
        path: '/manager/invite-colleague',
        name: 'InviteColleague',
        component: InviteColleague,
        meta: { layout: 'manager' } 
    },
    {
        path: '/manager/search-contractors',
        name: 'SearchContractors',
        component: SearchContractors,
        meta: { layout: 'manager' } 
    },
    {
        path: '/manager/contractors',
        name: 'ListContractors',
        component: ListContractors,
        meta: { layout: 'manager' } 
    },
    {
        path: '/manager/manage-groups',
        name: 'ManageGroups',
        component: ManageGroups,
        meta: { layout: 'manager' } 
    },
    {
        path: '/manager/invite-contractors',
        name: 'InviteConctractors',
        component: InviteContractors,
        meta: { layout: 'manager' } 
    },
    {
        path: '/manager/view-profile/:id',
        name: 'ViewProfile',
        component: ViewProfile,
        meta: { layout: 'manager' } 
    },
    {
        path: '/manager/manage-programmes',
        name: 'ManageProgrammes',
        component: ManageProgrammes,
        meta: { layout: 'manager' } 
    },
    {
        path: '/manager/offer-appointment/:id',
        name: 'OfferAppointment',
        component: OfferAppointment,
        meta: { layout: 'manager' }
    },
    {
        path: '/manager/edit-programme/:id',
        name: 'EditProgramme',
        component: EditProgramme,
        meta: { layout: 'manager' }
    },
    {
        path: '/manager/assignment-templates',
        name: 'AssignmentTemplates',
        component: AssignmentTemplates,
        meta: { layout: 'manager' } 
    },
    {
        path: '/manager/holiday-requests',
        name: 'HolidayRequests',
        component: HolidayRequests,
        meta: { layout: 'manager' } 
    },
    {
        path: '/manager/pending-tasks',
        name: 'PendingTasks',
        component: PendingTasks,
        meta: { layout: 'manager' } 
    },
    {
        path: '/manager/timesheet-history',
        name: 'TimesheetHistory',
        component: TimesheetHistory,
        meta: { layout: 'manager' } 
    },
    {
        path: '/manager/invoice-history',
        name: 'InvoiceHistory',
        component: InvoiceHistory,
        meta: { layout: 'manager' } 
    },
    {
        path: '/manager/change-password',
        name: 'ChangePassword',
        component: ChangePassword,
        meta: { layout: 'manager' } 
    },
    {
        path: '/register/step1',
        name: 'RegisterStep1',
        component: RegisterStep1
    },
    {
        path: '/register/step2',
        name: 'RegisterStep2',
        component: RegisterStep2
    },
    {
        path: '/register/step3',
        name: 'RegisterStep3',
        component: RegisterStep3
    },
    {
        path: '/contractor/my-profile',
        name: 'MyProfile',
        component: MyProfile,
        meta: { layout: 'contractor' }
    },
    {
        path: '/contractor/contact-details',
        name: 'ContractorContactDetails',
        component: ContractorContactDetails,
        meta: { layout: 'contractor' }
    },
    {
        path: '/contractor/my-compliance',
        name: 'MyCompliance',
        component: MyCompliance,
        meta: { layout: 'contractor' }
    },
    {
        path: '/contractor/checklist-for-academic-contractors',
        name: 'ChecklistForAcademicContractors',
        component: ChecklistForAcademicContractors,
        meta: { layout: 'contractor' }
    },
    {
        path: '/contractor/my-payments',
        name: 'MyPayments',
        component: MyPayments,
        meta: { layout: 'contractor' }
    },
    {
        path: '/contractor/my-appointments',
        name: 'MyAppointments',
        component: MyAppointments,
        meta: { layout: 'contractor' }
    },
    {
        path: '/contractor/my-notifications',
        name: 'MyNotifications',
        component: MyNotifications,
        meta: { layout: 'contractor' }
    },
    {
        path: '/contractor/help-support',
        name: 'HelpSupport',
        component: HelpSupport,
        meta: { layout: 'contractor' }
    },
    {
        path: '/contractor/change-password',
        name: 'ContractorChangePassword',
        component: ContractorChangePassword,
        meta: { layout: 'contractor' }
    },    
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach(async (to, from, next) => {
    if (to.path.startsWith('/manager') || to.path.startsWith('/contractor')) {
      const isAuth = await isAuthenticated();
      if (!isAuth) {
        console.log('Not authenticated');
        next('/login');
      } else {
        // console.log('authenticated');
        next();
      }
    } else {
      next();
    }
});

async function isAuthenticated() {
    try {
        const user = await getCurrentAuthenticatedUser();

        // Check if the returned user object has valid properties
        if (user && user.sub !== undefined && user.username !== undefined) {
            return true;  // User is authenticated
        } else {
            return false; // User object is invalid, hence not authenticated
        }
    } catch (error) {
        console.log("Authentication error:", error);
        return false; // An error occurred, user is not authenticated
    }
}


export default router;
