<template>
    <aside class="bg-uol border-right vh-100 overflow-auto" id="sidebar-wrapper">
        <div class="sidebar-heading">
            <img src="/uol-logo.svg" alt="Logo" height="70" class="d-inline-block align-top">
        </div>
      <div class="sidebar-heading">My Profile</div>
      <div class="list-group list-group-flush bg-uol">
        <router-link to="/contractor/my-profile" class="list-group-item list-group-item-action bg-uol"><i class="bi bi-person-lines-fill"></i> My Profile</router-link>
        <router-link to="/contractor/my-payments" class="list-group-item list-group-item-action bg-uol"><i class="bi bi-wallet2"></i> My Payments</router-link>
  
        <div class="sidebar-heading">My Appointments</div>
        <router-link to="/contractor/my-appointments" class="list-group-item list-group-item-action bg-uol"><i class="bi bi-calendar-check"></i> My Appointments</router-link>
  
        <div class="sidebar-heading">Notifications</div>
        <router-link to="/contractor/my-notifications" class="list-group-item list-group-item-action bg-uol">
            <i class="bi bi-bell"></i> Notifications
            <span class="badge bg-info">3</span>
        </router-link>
  
        <div class="sidebar-heading">Help & Support</div>
        <router-link to="/contractor/help-support" class="list-group-item list-group-item-action bg-uol"><i class="bi bi-question-circle"></i> Help & Support</router-link>
  
        <div class="sidebar-heading">Settings</div>
        <router-link to="/contractor/change-password" class="list-group-item list-group-item-action bg-uol"><i class="bi bi-key"></i> Change Password</router-link>
        <router-link to="/logout" class="list-group-item list-group-item-action bg-uol"><i class="bi bi-box-arrow-right"></i> Sign Out</router-link>
      </div>
    </aside>
  </template>
  
  <script>
  export default {
    name: 'ContractorSidebar'
  };
  </script>
  
  <style>
    .bg-uol {
        background-color: #1B153E;
        color: #fff;
    }
    #sidebar-wrapper {
        min-height: 100vh;
        width: 250px;
        position: fixed;
        top: 0;
        left: 0;
        overflow-y: auto;
    }
    div.list-group a {
        background-color: #1B153E !important;
        color: #fff !important;
    }
    div.list-group a:hover {
        border-left: 3px solid #E22F24 !important;
    }
    .sidebar-heading {
        padding: 0.875rem 1.25rem;
        font-size: 1.2em;
        font-weight: bold;
    }
    .list-group {
        width: 100%;
    }
    .list-group-item {
        border: none;
        border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
        border-width: 0 0 var(--bs-list-group-border-width) 0 !important;
    }
    .list-group-item i {
        margin-right: 10px;
    }
    .list-group-item-action:hover, .list-group-item-action:focus {
        color: #495057;
        background-color: #f8f9fa;
    }
  </style>
  