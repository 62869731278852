<template>
  <div class="container mt-4">
    <div class="card">
      <div class="card-body">
        <h1 class="card-title">Invite a Colleague</h1>
        <p>
          This page allows you to invite a colleague from UoL Academic Contractors to register so they can use the system.
        </p>
        <p>
          You will be notified once the invited manager completes the set-up process. N.B. whoever you invite must have a valid reason for using UoL Academic Contractors Portal, and their email address must be on your email system (i.e london.ac.uk).
        </p>
        <div class="row">
          <div class="col-md-6">
            <div class="row">
              <div class="form-group col-6">
                <label for="firstName">First Name:*</label>
                <input class="form-control" type="text" id="firstName" name="firstName" value="">
              </div>
              <div class="form-group  col-6">
                <label for="lastName">Last Name:*</label>
                <input class="form-control" type="text" id="lastName" name="lastName" value="">
              </div>
              </div>
              <div class="form-group">
                <label for="email">Email:*</label>
                <div class="input-group">
                  <input class="form-control" type="text" name="usernamePrefix" id="usernamePrefix" value="" pattern="[A-Za-z0-9\.\+_]{1,}" title="Please enter only email prefix not full email address, selected email suffice will automatically inserted.">
                  <div class="input-group-append">
                    <select class="form-control" name="emailSuffix">
                      <option value="@kcl.ac.uk">@kcl.ac.uk</option>
                      <option value="@bbk.ac.uk">@bbk.ac.uk</option>
                      <option value="@gold.ac.uk">@gold.ac.uk</option>
                      <option value="@ucl.ac.uk">@ucl.ac.uk</option>
                      <option value="@lshtm.ac.uk">@lshtm.ac.uk</option>
                      <option value="@lse.ac.uk">@lse.ac.uk</option>
                      <option value="@rhul.ac.uk">@rhul.ac.uk</option>
                      <option value="@rcp.ac.uk">@rcp.ac.uk</option>
                      <option value="@rvc.ac.uk">@rvc.ac.uk</option>
                      <option value="@sas.ac.uk">@sas.ac.uk</option>
                      <option value="@london.ac.uk">@london.ac.uk</option>
                      <option value="@qmul.ac.uk">@qmul.ac.uk</option>
                      <option value="@city.ac.uk">@city.ac.uk</option>
                    </select>

                  </div>
                </div>
              </div>
          </div>
        </div>
        <div class="form-group">
          <label for="subject">Subject:*</label>
          <input class="form-control" type="text" id="subject" name="subject" value="UoL - Manager Invitation">
        </div>
        <div class="form-group">
          <label for="emailBody">Email Body:*</label>
          <textarea class="form-control" name="emailBody" id="emailBody" rows="4">You have been invited to register as a Hiring Manager and create your profile on UoL using the below secure link.\n\nOnce you have completed registration, your account will be activated and you can begin using this account for managing programmes.</textarea>
        </div>
        <p>Below is a link which will take you to a secure page to complete your contact details and activate your account.</p>
        <p>[secure link to registration page goes there]</p>
        <p>If you have any questions or problems at this point please contact UoL Help Team on 020 123 1234.</p>
        <p>Kind Regards,<br>UoL Help Team</p>
        <div class="text-center">
          <button class="btn btn-primary">Send Invite</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    formattedViewName() {
      // Grabs the route name and adds a space before each capital letter
      return this.$route.name.replace(/([A-Z])/g, ' $1').trim();
    },
  },
};
</script>
