<template>
    <div class="container mt-3" v-if="contractor">
        <div class="row">
            <div class="col-md-3">
                <!-- Profile Picture and Basic Info -->
                <div class="card">
                    <img :src="contractor.profilePic" class="card-img-top" alt="Profile Picture">
                    <div class="card-body">
                        <h5 class="card-title">{{ contractor.name }}</h5>
                        <p class="card-text">{{ contractor.organisation }}</p>
                        <p class="card-text"><small class="text-muted">{{ contractor.username }}</small></p>
                    </div>
                </div>
            </div>
            <div class="col-md-8">
                <!-- Accordion for sections -->
                <div class="accordion" id="accordionExample">
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="headingOne">
                            <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                Academic Contractor Onboarding Checklist
                            </button>
                        </h2>
                        <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
                            data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                <div class="checklist">
                                    <p v-for="(item, index) in checklist" :key="index" class="checklist-item">
                                        <i
                                            :class="{ 'bi bi-check-circle-fill text-success': item.completed, 'bi bi-circle text-muted': !item.completed }"></i>
                                        {{ item.label }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Contact Details Section -->
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="headingTwo">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                Contact Details
                            </button>
                        </h2>
                        <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                            data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                <div class="contact-details">
                                    <div v-if="!editContact">
                                        <p><i class="bi bi-envelope-fill"></i> Email: {{ contractor.email }}</p>
                                        <p><i class="bi bi-telephone-fill"></i> Phone: {{ contractor.phone }}</p>
                                        <p><i class="bi bi-geo-alt-fill"></i> Address:</p>
                                        <p class="ms-4"> {{ contractor.address1 }}</p>
                                        <p class="ms-4"> {{ contractor.address2 }}</p>
                                        <p class="ms-4"> {{ contractor.town }}</p>
                                        <p class="ms-4"> {{ contractor.postcode }}</p>
                                        <button class="btn btn-sm btn-secondary mt-2" @click="editContact = true">Edit</button>
                                    </div>
                                    <div v-else class="card mb-3">
                                        <div class="card-body">
                                            <form @submit.prevent="saveContactDetails">
                                                <div class="mb-3">
                                                    <label for="email" class="form-label">Email</label>
                                                    <input v-model="editableContact.email" type="email" id="email"
                                                        class="form-control" placeholder="Email" required>
                                                </div>
                                                <div class="mb-3">
                                                    <label for="phone" class="form-label">Phone</label>
                                                    <input v-model="editableContact.phone" type="text" id="phone"
                                                        class="form-control" placeholder="Phone" required>
                                                </div>
                                                <div class="mb-3">
                                                    <label for="address1" class="form-label">Address 1</label>
                                                    <input v-model="editableContact.address1" type="text" id="address1"
                                                        class="form-control" placeholder="Address 1" required>
                                                </div>
                                                <div class="mb-3">
                                                    <label for="address2" class="form-label">Address 2</label>
                                                    <input v-model="editableContact.address2" type="text" id="address2"
                                                        class="form-control" placeholder="Address 2">
                                                </div>
                                                <div class="mb-3">
                                                    <label for="town" class="form-label">Town/City</label>
                                                    <input v-model="editableContact.town" type="text" id="town"
                                                        class="form-control" placeholder="Town/City" required>
                                                </div>
                                                <div class="mb-3">
                                                    <label for="postcode" class="form-label">Postcode</label>
                                                    <input v-model="editableContact.postcode" type="text" id="postcode"
                                                        class="form-control" placeholder="Postcode" required>
                                                </div>
                                                <div class="text-end">
                                                    <button type="submit" class="mx-1 btn-sm btn btn-primary">Save</button>
                                                    <button type="button" class="mx-1 btn-sm btn btn-secondary"
                                                        @click="cancelContactEdit">Cancel</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Employment History Section -->
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="headingThree">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                Employment History
                            </button>
                        </h2>
                        <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"
                            data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                <div v-for="(job, index) in editableEmploymentHistory" :key="index" class="card mb-3">
                                    <div class="card-body">
                                        <form @submit.prevent="saveEmploymentHistory(index)">
                                            <div v-if="!job.editMode">
                                                <h5 class="card-title">{{ job.jobTitle }} at {{ job.employer }}</h5>
                                                <p>{{ job.startDate }} - {{ job.endDate }}</p>
                                                <p>{{ job.description }}</p>
                                                <button type="button" class="mx-1 btn-sm btn btn-primary"
                                                    @click="editEmploymentHistory(index)">Edit</button>
                                                <button type="button" class="mx-1 btn-sm btn btn-danger"
                                                    @click="removeEmploymentHistory(index)">Remove</button>
                                            </div>
                                            <div v-else class="row g-3">
                                                <div class="col-12">
                                                    <label for="jobTitle-{{ index }}" class="form-label">Job
                                                        Title</label>
                                                    <input v-model="job.jobTitle" type="text" id="jobTitle-{{ index }}"
                                                        class="form-control" placeholder="Job Title" required>
                                                </div>
                                                <div class="col-12">
                                                    <label for="employer-{{ index }}"
                                                        class="form-label">Employer</label>
                                                    <input v-model="job.employer" type="text" id="employer-{{ index }}"
                                                        class="form-control" placeholder="Employer" required>
                                                </div>
                                                <div class="col-md-6">
                                                    <label for="startDate-{{ index }}" class="form-label">Start
                                                        Date</label>
                                                    <input v-model="job.startDate" type="date"
                                                        id="startDate-{{ index }}" class="form-control"
                                                        placeholder="Start Date" required>
                                                </div>
                                                <div class="col-md-6">
                                                    <label for="endDate-{{ index }}" class="form-label">End Date</label>
                                                    <input v-model="job.endDate" type="date" id="endDate-{{ index }}"
                                                        class="form-control" placeholder="End Date" required>
                                                </div>
                                                <div class="col-12">
                                                    <label for="description-{{ index }}"
                                                        class="form-label">Description</label>
                                                    <textarea v-model="job.description" id="description-{{ index }}"
                                                        class="form-control" placeholder="Description"
                                                        required></textarea>
                                                </div>
                                                <div class="col-12 text-end">
                                                    <button type="submit" class="mx-1 btn-sm btn btn-primary">Save</button>
                                                    <button type="button" class="mx-1 btn-sm btn btn-secondary"
                                                        @click="cancelEmploymentEdit(index)">Cancel</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>

                                <button class="btn btn-success mt-2" @click="addEmploymentHistory">Add New</button>
                            </div>
                        </div>
                    </div>
                    <!-- Compliance Link Button -->
                    <div class="mt-3 text-end">
                        <router-link to="/contractor/my-compliance" class="btn btn-primary">
                            <i class="bi bi-shield-lock-fill"></i> Go to Compliance
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-else>
        <p>Contractor not found.</p>
    </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import contractorsData from '../../mock/contractors.json';

export default {
    setup() {
        const contractor = ref(null);
        const editContact = ref(false);
        const editableContact = ref({});
        const editableEmploymentHistory = ref([]);
        const checklist = ref([
            { label: 'Registration', completed: true },
            { label: 'Complete Right to Work Document Verification via ID Pal', completed: true },
            { label: 'Bank Details On-File', completed: true }
        ]);

        onMounted(() => {
            const contractorId = 2;  // Assume this is the logged-in contractor's ID
            const foundContractor = contractorsData.find(c => c.id === contractorId);
            if (foundContractor) {
                contractor.value = foundContractor;
                editableContact.value = { ...foundContractor };  // Clone for editability
                editableEmploymentHistory.value = foundContractor.employmentHistory.map(job => ({
                    ...job,
                    editMode: false
                }));
            }
        });

        function editEmploymentHistory(index) {
            editableEmploymentHistory.value[index].editMode = true;
        }

        function saveEmploymentHistory(index) {
            editableEmploymentHistory.value[index].editMode = false;
            // You'd typically make an API call here to save the data
        }

        function cancelEmploymentEdit(index) {
            // Reset to original data or clear edits
            editableEmploymentHistory.value[index] = contractor.value.employmentHistory[index];
            editableEmploymentHistory.value[index].editMode = false;
        }

        function removeEmploymentHistory(index) {
            editableEmploymentHistory.value.splice(index, 1);
            // An API call to update the backend might be required here
        }

        function addEmploymentHistory() {
            editableEmploymentHistory.value.push({ jobTitle: '', employer: '', startDate: '', endDate: '', description: '', editMode: true });
        }

        function saveContactDetails() {
            editContact.value = false;
            contractor.value = { ...editableContact.value };
            // Here you would typically make an API call to save the updated contact details
        }

        function cancelContactEdit() {
            editableContact.value = { ...contractor.value };
            editContact.value = false;
        }

        return {
            checklist,
            contractor,
            editContact,
            editableContact,
            editableEmploymentHistory,
            editEmploymentHistory,
            saveEmploymentHistory,
            cancelEmploymentEdit,
            removeEmploymentHistory,
            addEmploymentHistory,
            saveContactDetails,
            cancelContactEdit
        };
    }
};
</script>