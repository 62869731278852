<template>
  <div class="container mt-2">
    <div class="card">
      <div class="card-body">
        <h2 class="card-title">{{ formattedViewName }}</h2>

        <div id="message"></div>

        <div class="d-flex justify-content-end mb-3">
          <!-- Items per page buttons -->
          <div class="btn-group" role="group" aria-label="Basic outlined example">
            <a class="btn btn-outline-info btn-sm btn-round mr-2 mb-2 text-dark" href="#"
              :class="{ 'active': selectedButton === '50' }" @click="selectButton('50')">50</a>
            <a class="btn btn-outline-info btn-sm btn-round mr-2 mb-2 text-dark" href="#"
              :class="{ 'active': selectedButton === '75' }" @click="selectButton('75')">75</a>
            <a class="btn btn-outline-info btn-sm btn-round mr-2 mb-2 text-dark" href="#"
              :class="{ 'active': selectedButton === '100' }" @click="selectButton('100')">100</a>
          </div>
        </div>

        <p><strong>Showing 1 - 10 of 303 results (Searched within University Talent Bank pool)</strong></p>

        <!-- Search results table -->
        <table class="table">
          <thead>
            <tr>
              <th>Full Name</th>
              <th>Federation Member</th>
              <th class="text-center">Compliant</th>
              <th class="text-center">Currently Working</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="result in contractors" :key="result.id">
              <td class="align-middle">
                <router-link :to="{ name: 'ViewProfile', params: { id: result.id } }">
                  <img :src="result.profilePic" alt="Profile Picture" class="mr-2" width="50" />
                  {{ result.name }}
                </router-link>
              </td>
              <td>{{ result.organisation }}</td>

              <td class="text-center">
                <i v-if="result.isCompliant === true" class="bi bi-check-circle-fill text-success" style="font-size: 24px;"></i>
                <i v-else class="bi bi-x-circle-fill text-danger" style="font-size: 24px;"></i>
              </td>
              <td class="text-center">
                <i v-if="result.currentlyWorking === true" class="bi bi-check-circle-fill text-success" style="font-size: 24px;"></i>
                <i v-else class="bi bi-x-circle-fill text-danger" style="font-size: 24px;"></i>
              </td>
            </tr>
          </tbody>
        </table>

        <!-- Pagination -->
        <nav aria-label="Page navigation">
          <ul class="pagination">
            <li class="page-item" v-for="page in totalPages" :key="page">
              <a class="page-link" href="#">{{ page }}</a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
import contractors from '../../mock/contractors.json'; // Adjust the path as needed

export default {
  data() {
    return {
      contractors, // Populate this with your search results data
      totalPages: 10, // Set this based on your pagination logic
      selectedButton: '' // Initially no button is selected
    };
  },
  computed: {
    formattedViewName() {
      return this.$route.name.replace(/([A-Z])/g, ' $1').trim();
    }
  },
  methods: {
    selectButton(buttonValue) {
      this.selectedButton = buttonValue;
    }
  },
  mounted() {
    // Load search results and other necessary data here
  }
};
</script>
