<template>
  <div class="container mt-2">
    <div class="card">
      <div class="card-body">
        <h2 class="card-title">{{ formattedViewName }}</h2>

        <!-- Contact Details Section -->
        <div class="contact-details-section mb-3">
          <h3>Contact Details</h3>

          <!-- Read-Only Fields -->
          <div class="form-group">
            <label>Federation Member:</label>
            <input type="text" class="form-control" value="University of London" readonly>
          </div>

          <div class="form-group">
            <label>Programme:</label>
            <input type="text" class="form-control" value="Development Programme" readonly>
          </div>

          <div class="form-group">
            <label>Address:</label>
            <input type="text" class="form-control" value="123 London Street, London, UK" readonly>
          </div>

          <!-- Editable Fields -->
          <div class="form-group">
            <label>First Name:</label>
            <input type="text" class="form-control" v-model="firstName" placeholder="John">
          </div>

          <div class="form-group">
            <label>Last Name:</label>
            <input type="text" class="form-control" v-model="lastName" placeholder="Doe">
          </div>

          <div class="form-group">
            <label>Phone:</label>
            <input type="tel" class="form-control" v-model="phone" placeholder="+44 123 456 7890">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      firstName: 'John',
      lastName: 'Smith',
      phone: '+44 123 456 7890',
    };
  },
  computed: {
    formattedViewName() {
      return this.$route.name.replace(/([A-Z])/g, ' $1').trim();
    }
  }
};
</script>
