import { createStore } from 'vuex';

export default createStore({
  state: {
    step1Data: {
      title: 'Mr',
      firstName: 'John',
      middleName: 'David',
      lastName: 'Doe',
      knownAs: 'JD',
      address1: '123 Main Street',
      address2: 'Apt 101',
      town: 'London',
      county: 'Greater London',
      postcode: 'SW1A 1AA',
      phone: '0123456789',
      mobile: '9876543210',
      dob: '1990-01-01',
      nino: 'AB123456C',
      nationality: 'British',
      livingInUK: true,
      email: 'john.doe@primeinc.co.uk',
      password: 'Password123!',
      studentVisa: false,
      agreeTerms: true,
    },
    step2Data: {
      employmentStatus: 'PAYE',
      taxCode: '1250L',
      hasGradLoan: false,
      graduated: false,
      loanPlan: 'Plan 1',
      hasPostgradLoan: false,
      repayingDirectly: false,
      gender: 'Male',
      repayingPostgradLoan: false,
      bankName: 'Example Bank',
      sortCode: '12-34-56',
      accountNumber: '12345678',
      accountName: 'John D Doe',
      iban: 'GB29NWBK60161331926819',
      swiftBic: 'NWBKGB2L',
      routingNumber: '011000015',
      rollNumber: '12345678',
    },
    step3Data: {
      // Define step 3 mock data if needed
    },
  },
  mutations: {
    updateStep1Field(state, { field, value }) {
      state.step1Data[field] = value;
    },
    updateStep2Field(state, { field, value }) {
      state.step2Data[field] = value;
    },
    updateStep3Field(state, { field, value }) {
      state.step3Data[field] = value;
    },
    resetStepData(state, step) {
      if (step === 1) {
        state.step1Data = {
          title: 'Mr',
          firstName: 'John',
          middleName: 'David',
          lastName: 'Doe',
          knownAs: 'JD',
          address1: '123 Main Street',
          address2: 'Apt 101',
          town: 'London',
          county: 'Greater London',
          postcode: 'SW1A 1AA',
          phone: '0123456789',
          mobile: '9876543210',
          dob: '1990-01-01',
          nino: 'AB123456C',
          nationality: 'British',
          livingInUK: true,
          email: 'john.doe@example.com',
          password: 'password123',
          studentVisa: false,
          agreeTerms: true,
        };
      } else if (step === 2) {
        state.step2Data = {
          companyType: 'Ltd',
          taxCode: '1250L',
          hasGradLoan: false,
          graduated: true,
          loanPlan: 'Plan 1',
          hasPostgradLoan: false,
          repayingDirectly: true,
          gender: 'Male',
          bankName: 'Example Bank',
          sortCode: '12-34-56',
          accountNumber: '12345678',
          accountName: 'John D Doe',
          iban: 'GB29NWBK60161331926819',
          swiftBic: 'NWBKGB2L',
          routingNumber: '011000015',
        };
      } else if (step === 3) {
        state.step3Data = {
          // Define step 3 mock data if needed
        };
      }
    },
  },
  actions: {
    updateField({ commit }, { step, field, value }) {
      commit(`updateStep${step}Field`, { field, value });
    },
    resetStepData({ commit }, step) {
      commit('resetStepData', step);
    },
  },
  getters: {
    getStep1Data: (state) => state.step1Data,
    getStep2Data: (state) => state.step2Data,
    getStep3Data: (state) => state.step3Data,
  },
});
