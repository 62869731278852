<template>
  <div class="container mt-2">
    <div class="card">
      <div class="card-body">
        <h2 class="card-title">{{ formattedViewName }}</h2>
        <form @submit.prevent="submitForm">
          <div class="input-group mb-3">
            <input type="text" class="form-control" id="q" v-model="formData.q" />
            <button type="submit" class="btn btn-primary">Search</button>
          </div>
        </form>
          <table class="table">
          <thead>
            <tr>
              <th>Assignment ID</th>
              <th>Title</th>
              <th>Schedule</th>
              <th>Start Date</th>
              <th>End Date</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="programme in programmes" :key="programme.id">
              <td>
                <router-link :to="'/manager/edit-programme/' + programme.id + '#programme=3'">
                  DTA-{{ programme.id }}
                </router-link>
              </td>
              <td>{{ programme.title }}</td>
              <td>{{ programme.paymentScheduleSelect }}</td>
              <td>{{ programme.startDate }}</td>
              <td>{{ programme.endDate }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import programmes from '../../mock/programmeFeed.json';

export default {
  data() {
    return {
      formData: {
        q: '' // Initialize q as an empty string
      }
    };
  },
  computed: {
    formattedViewName() {
      // Grabs the route name and adds a space before each capital letter
      return this.$route.name.replace(/([A-Z])/g, ' $1').trim();
    },
    programmes() {
      // Use the imported JSON data from programmeFeed.json
      return programmes;
    }
  },
  methods: {
    submitForm() {
      // Implement your form submission logic here
      console.log('Form submitted:', this.formData.q);
    }
  }
};
</script>


