<template>
    <div  v-if="userDetails">
        <div class="row">
            <!-- Left-hand Sidebar -->
            <div class="col-md-2 px-0 py-3 border rounded text-white" style="background-color: #1B153E;">
                <div class="sidebar-heading">
                    <img src="/uol-logo.svg" alt="Logo" height="70" class="d-inline-block align-top">
                </div>
                <div class="mx-3 p-3 my-5 border rounded">
                    <h5>Step 3 of 3</h5>
                    <p>Please check these details are correct.</p>
                    <p>Once you click 'Log me in' you will receive an email with further instructions on what happens
                        next.</p>
                </div>
            </div>

            <!-- Main Content Area -->
            <div class="col-md-10">
                <header class="my-3">
                    <div class="d-flex justify-content-between align-items-center">
                        <h3 class="m-0">{{ userDetails.firstName }} {{ userDetails.lastName }}</h3>
                        <span>Updated {{ timeSinceUpdate }}</span>
                    </div>
                </header>
                <h2>University of London Academic Contractor Registration Page</h2>
                <p>Please check these details are correct.</p>
                <div class="card my-3">
                    <div class="card-header">
                        <h5>Your Personal Details</h5>
                    </div>
                    <div class="card-body">
                        <p><strong>{{ userDetails.title }} {{ userDetails.firstName }} {{ userDetails.middleName }} {{
                            userDetails.lastName }}</strong> |
                            NINO: {{ userDetails.nino }} | Student: {{ userDetails.studentVisa ? 'Yes' : 'No' }}
                            | Nationality: {{ userDetails.nationality }} | DOB: {{ userDetails.dob }}</p>
                        <p>Address: {{ userDetails.address1 }}, {{ userDetails.address2 }}, {{ userDetails.town }}, {{
                            userDetails.county }}, {{ userDetails.postcode }}</p>
                        <p>Email: {{ userDetails.email }} | Phone: {{ userDetails.phone }} | Mobile: {{
                            userDetails.mobile }}</p>
                    </div>
                </div>

                <!-- Bank Details Section -->
                <div class="card my-3">
                    <div class="card-header">
                        <h5>Your Bank Details</h5>
                    </div>
                    <div class="card-body">
                        <p>Account Name: {{ userDetails.accountName }}</p>
                        <p>Bank Name: {{ userDetails.bankName }}</p>
                        <p>Account Number: {{ userDetails.accountNumber }}</p>
                        <p>Sort Code: {{ userDetails.sortCode }}</p>
                        <p>IBAN: {{ userDetails.iban }}</p>
                        <p>SWIFT/BIC: {{ userDetails.swiftBic }}</p>
                        <p>Routing Number: {{ userDetails.routingNumber }}</p>
                        <p>Roll Number: {{ userDetails.rollNumber }}</p>
                    </div>
                </div>

                <!-- Action Buttons -->
                <div class="d-flex justify-content-between m-2">
                    <button class="btn btn-primary" @click="$router.go(-1)">&laquo; Step 2</button>
                    <a href="/login" class="btn btn-primary">Confirm Details & Log me in &raquo;</a>
                </div>

                <!-- Completion Blurb -->
                <div class="mt-3">
                    <p class="bg-warning text-dark p-2 rounded">Thank you for completing your registration for the
                        University of London Academic Contractors Portal. Please check your inbox for the Welcome email,
                        which provides instructions on what to do next. If you do not receive this, please check your
                        junk folder.</p>
                </div>
            </div>
        </div> 
    </div>
    <div  v-else>
        <div class="alert alert-danger" role="alert">
            <h4 class="alert-heading">Error</h4>
            <p>There was an error fetching your details. Please try again later.</p>
        </div>
    </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { generateClient } from 'aws-amplify/api';
import { gql } from 'graphql-tag';

export default {
    setup() {
        const userDetails = ref(null);
        const client = generateClient();
        const timeSinceUpdate = ref('less than a minute ago');

        const fetchUserDetails = async () => {
            const userId = localStorage.getItem('userid');
            if (!userId) {
                console.error('No user ID found');
                return;
            }

            try {
                // Use the query, not the mutation
                const response = await client.graphql({
                    query: gql`query GetAcademicContractor($id: ID!) {
                        getAcademicContractor(id: $id) {
                            id
                            title
                            firstName
                            lastName
                            middleName
                            nino
                            studentVisa,
                            nationality,
                            dob,
                            address1,
                            address2,
                            town,
                            county,
                            postcode,
                            email,
                            phone,
                            mobile,
                            accountName,
                            bankName,
                            accountNumber,
                            sortCode,
                            iban,
                            swiftBic,
                            routingNumber,
                            rollNumber,
                            


                        }
                    }`,
                    variables: { id: userId },
                });

                if (response.data && response.data.getAcademicContractor) {
                    userDetails.value = response.data.getAcademicContractor;
                } else {
                    console.error('User details not fetched');
                }
            } catch (error) {
                console.error('Error fetching user details:', error);
            }
        };

        onMounted(() => {
            fetchUserDetails();
            setInterval(() => {
                timeSinceUpdate.value = 'More than a minute ago';
            }, 60000);
        });

        return {
            userDetails,
            timeSinceUpdate
        };
    },
};
</script>



<style>
/* Optional styling for better visual appearance */
.container-fluid {
    padding-top: 20px;
}

.card-header {
    background-color: #1B153E;
    color: white;
}

.card-body p {
    margin: 0;
    padding: 2px;
}

.btn-primary {
    background-color: #1B153E;
    border-color: #1B153E;
}

.btn-primary:hover {
    background-color: #0f0f2f;
    border-color: #0f0f2f;
}

.bg-warning {
    background-color: #fffdcc !important;
}
</style>
