<template>
   <div class="container mt-2">
    <div class="card">
      <div class="card-body">
        <h3 class="card-title">External / Intercollegiate Examiner Nominations</h3>

        <div class="filters m-1 text-end">
          <div class="input-group m-1">
            <span class="input-group-text">From</span>
            <input type="date" class="form-control" v-model="fromDate" />

            <span class="input-group-text">To</span>
            <input type="date" class="form-control" v-model="toDate" />

            <select class="form-select" v-model="filterStatus">
              <option value="">Filter by Status</option>
              <option value="Pending">Pending</option>
              <option value="Accepted">Accepted</option>
              <option value="Declined">Declined</option>
            </select>
            <button class="btn btn-secondary" @click="downloadSelectedData">Download</button>
          </div>
        </div>

        <table class="table table-striped table-hover mt-3">
          <thead>
            <tr>
              <th scope="col">Federation Member</th>
              <th scope="col">Programme</th>
              <th scope="col">Programme Contact</th>
              <th scope="col">Contractor</th>
              <th scope="col">Date Received</th>
              <th scope="col">Date Reviewed</th>
              <th scope="col">Status</th>
              <th scope="col">CV Link</th>
              <th scope="col">Nomination Form</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(nomination, index) in filteredNominations" :key="nomination.id"
              :class="{ 'bg-success': nomination.status === 'Accepted', 'bg-danger': nomination.status === 'Declined' }">
              <td>{{ nomination.federationMember }}</td>
              <td>{{ nomination.programme }}</td>
              <td>{{ nomination.programmeContact }}</td>
              <td>{{ nomination.academicContractor }}</td>
              <td>{{ formatDate(nomination.dateReceived) }}</td>
              <td>{{ formatDate(nomination.dateReviewed) }}</td>
              <td>{{ nomination.status}}</td>
              <td><a :href="nomination.cvLink" target="_blank">View CV</a></td> <!-- Adjusted for dynamic CV link -->
              <td><a :href="nomination.formLink" target="_blank">View Form</a></td> <!-- Adjusted for dynamic form link -->
              <td>
                <button class="btn btn-primary" @click="showModal(index)" :disabled="nomination.status === 'Accepted' || nomination.status === 'Declined'"
                    :class="{ 'btn-secondary': nomination.status === 'Accepted' || nomination.status === 'Declined' }">
                    Select Action
                </button>
              </td>
            </tr>
          </tbody>
        </table>

        <!-- Modal -->
        <div class="modal fade" id="actionModal" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="modalLabel">Nomination Action</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <div class="mb-3">
                  <label for="action-select" class="form-label">Action:</label>
                  <select id="action-select" class="form-select" v-model="currentNomination.selectedAction"
                    @change="updateReasons">
                    <option value="">Please select</option>
                    <option value="Accept">Accept</option>
                    <option value="Decline">Decline</option>
                  </select>
                </div>
                <div v-if="currentNomination.selectedAction === 'Decline'">
                  <label for="reason-select" class="form-label">Reason for Decline:</label>
                  <select id="reason-select" class="form-select" v-model="currentNomination.selectedReason">
                    <option value="">Please select</option>
                    <option v-for="reason in declineReasons" :key="reason" :value="reason">{{ reason }}</option>
                  </select>
                </div>
                <div class="mb-3">
                  <label for="comment-text" class="form-label">Comment:</label>
                  <textarea id="comment-text" class="form-control" v-model="currentNomination.comment"></textarea>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary" @click="submitAction">Submit</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, watch } from 'vue';
import nominationsData from '@/mock/nominations.json';
import { Modal } from 'bootstrap';
import { saveAs } from 'file-saver'; // Ensure you've installed this package for downloading functionality

export default {
  setup() {
    const nominations = ref(nominationsData);
    const filteredNominations = ref([...nominationsData]);
    const fromDate = ref('');
    const toDate = ref('');
    const filterStatus = ref('');
    const currentIndex = ref(null);
    const currentNomination = ref({
      selectedAction: '',
      selectedReason: '',
      comment: ''
    });
    const declineReasons = ref([
      "Lacks relevant qualifications",
      "Lacks knowledge of UK sector agreed reference points and standards",
      "Lacks knowledge of how a subject discipline is delivered across comparable UK Higher Education institutions",
      "Has prior or concurrent role in the approval, (re)development, or periodic review of a programme, or parts thereof (i.e. acting as an external subject specialist)",
      "Is a member of a governing body or committee of the University of London or one of its collaborative partners, or a current employee of the University or Federation Member",
      "Has a close professional, contractual or personal relationship with a member of staff or student involved with the programme of study",
      "Has recent or current substantive collaborative research activities with a member of staff closely involved in the delivery, management or assessment of the programme(s) or courses/modules in question",
      "Has former employment or registration as a student with the programmes offered through University of London Worldwide or Federation Member unless a period of five years has elapsed and all students taught by or with the External Examiner or Intercollegiate Examiner have completed their programme(s)",
      "Holds more than two External Examiner or Intercollegiate Examiner roles on taught programmes",
      "Holds a simultaneous External/Intercollegiate appointment at another Federation Member of the University of London.",
      "Based outside the UK",
      "Other/Multiple"
    ]);

    watch([fromDate, toDate, filterStatus], () => {
      filteredNominations.value = nominations.value.filter(nomination => {
        const statusMatch = filterStatus.value ? nomination.status === filterStatus.value : true;
        const fromDateMatch = fromDate.value ? new Date(nomination.dateReceived) >= new Date(fromDate.value) : true;
        const toDateMatch = toDate.value ? new Date(nomination.dateReceived) <= new Date(toDate.value) : true;
        return statusMatch && fromDateMatch && toDateMatch;
      });
    });

    const applyFilters = () => {
      // This function manually triggers the filtering logic, if needed.
      // It's here for completeness; the watch above automatically handles filtering.
    };

    const showModal = (index) => {
      currentIndex.value = index;
      Object.assign(currentNomination.value, nominations.value[index], { selectedReason: '', comment: '' });
      new Modal(document.getElementById('actionModal')).show();
    };

    const updateReasons = () => {
      if (currentNomination.value.selectedAction === 'Accept') {
        currentNomination.value.selectedReason = '';
      }
    };

    const formatDate = (dateString) => {
      if (!dateString) return 'n/a';
      const date = new Date(dateString);
      if (isNaN(date)) return 'n/a';
      return date.toLocaleDateString('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      });
    };

    const submitAction = () => {
      nominations.value[currentIndex.value] = { ...currentNomination.value };
      new Modal(document.getElementById('actionModal')).hide();
    };

    const downloadSelectedData = () => {
      const csvContent = filteredNominations.value.map(nom => Object.values(nom).join(",")).join("\n");
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      saveAs(blob, "nominations.csv");
    };

    return {
      nominations,
      filteredNominations,
      fromDate,
      toDate,
      filterStatus,
      currentIndex,
      currentNomination,
      declineReasons,
      showModal,
      updateReasons,
      submitAction,
      downloadSelectedData,
      formatDate,
      applyFilters
    };
  },
};
    
</script>
