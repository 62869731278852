<template>
  <div class="container mt-2">
    <div class="card">
      <div class="card-body">
        <h2 class="card-title">{{ formattedViewName }}</h2>
        <form @submit.prevent="submitForm">
          <div class="input-group mb-3">
            <input type="text" class="form-control" id="q" v-model="formData.q" />
            <button type="submit" class="btn btn-primary">Search</button>
          </div>
        </form>

        <table class="mx-2 table table-striped table-hover mt-3 text-center">
          <thead>
            <tr>
              <th scope="col">Invited contractor</th>
              <th scope="col">Completed invitation</th>
              <th scope="col">Invited by HM</th>
              <th scope="col">Invited through FM</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="nomination in filteredNominations" :key="nomination.id"
              :class="{ 'bg-success': nomination.status === 'Accepted', 'bg-danger': nomination.status === 'Declined' }">
              <td>{{ nomination.academicContractor }}</td>
              <td>{{ nomination.status }}</td>
              <td>
                <i v-if="nomination.hmInvited === true" class="bi bi-check-circle-fill text-success"
                  style="font-size: 24px;"></i>
                <i v-else class="bi bi-x-circle-fill text-danger" style="font-size: 24px;"></i>
              </td>
              <td>
                <i v-if="nomination.fmInvited === true" class="bi bi-check-circle-fill text-success"
                  style="font-size: 24px;"></i>
                <i v-else class="bi bi-x-circle-fill text-danger" style="font-size: 24px;"></i>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td colspan="5" class="text-end">
                <router-link to="/manager/invite-contractors">
                  <button role="button" class="btn btn-primary">
                    <i class="bi bi-plus-circle-fill me-2"></i> Add Contractor
                  </button>
                </router-link>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, watch } from 'vue';
import { useRouter } from 'vue-router';
import { Modal } from 'bootstrap';
import { saveAs } from 'file-saver';

import nominationsData from '@/mock/nominations.json';

export default {
  setup() {
    const nominations = ref(nominationsData);
    const filteredNominations = ref([...nominationsData]);
    const fromDate = ref('');
    const toDate = ref('');
    const filterStatus = ref('');
    const currentIndex = ref(null);
    const currentNomination = ref({
      selectedAction: '',
      selectedReason: '',
      comment: ''
    });

    const formData = ref({ q: '' });

    const router = useRouter();

    const submitForm = () => {
      // Build the query string based on formData
      // Perform the request and then redirect
      router.push({ path: '/manager/contractors', query: formData.value });
    };

    const formattedViewName = computed(() => {
      return router.currentRoute.value.name.replace(/([A-Z])/g, ' $1').trim();
    });

    const declineReasons = ref([
      "Lacks relevant qualifications",
      "Lacks knowledge of UK sector agreed reference points and standards",
      "Lacks knowledge of how a subject discipline is delivered across comparable UK Higher Education institutions",
      "Has prior or concurrent role in the approval, (re)development, or periodic review of a programme, or parts thereof (i.e. acting as an external subject specialist)",
      "Is a member of a governing body or committee of the University of London or one of its collaborative partners, or a current employee of the University or Federation Member",
      "Has a close professional, contractual or personal relationship with a member of staff or student involved with the programme of study",
      "Has recent or current substantive collaborative research activities with a member of staff closely involved in the delivery, management or assessment of the programme(s) or courses/modules in question",
      "Has former employment or registration as a student with the programmes offered through University of London Worldwide or Federation Member unless a period of five years has elapsed and all students taught by or with the External Examiner or Intercollegiate Examiner have completed their programme(s)",
      "Holds more than two External Examiner or Intercollegiate Examiner roles on taught programmes",
      "Holds a simultaneous External/Intercollegiate appointment at another Federation Member of the University of London.",
      "Based outside the UK",
      "Other/Multiple"
    ]);

    watch([fromDate, toDate, filterStatus], () => {
      filteredNominations.value = nominations.value.filter(nomination => {
        const statusMatch = filterStatus.value ? nomination.status === filterStatus.value : true;
        const fromDateMatch = fromDate.value ? new Date(nomination.dateReceived) >= new Date(fromDate.value) : true;
        const toDateMatch = toDate.value ? new Date(nomination.dateReceived) <= new Date(toDate.value) : true;
        return statusMatch && fromDateMatch && toDateMatch;
      });
    });

    const applyFilters = () => {
      // This function manually triggers the filtering logic, if needed.
      // It's here for completeness; the watch above automatically handles filtering.
    };

    const showModal = (index) => {
      currentIndex.value = index;
      Object.assign(currentNomination.value, nominations.value[index], { selectedReason: '', comment: '' });
      new Modal(document.getElementById('actionModal')).show();
    };

    const updateReasons = () => {
      if (currentNomination.value.selectedAction === 'Accept') {
        currentNomination.value.selectedReason = '';
      }
    };

    const formatDate = (dateString) => {
      if (!dateString) return 'n/a';
      const date = new Date(dateString);
      if (isNaN(date)) return 'n/a';
      return date.toLocaleDateString('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      });
    };

    const submitAction = () => {
      nominations.value[currentIndex.value] = { ...currentNomination.value };
      new Modal(document.getElementById('actionModal')).hide();
    };

    const downloadSelectedData = () => {
      const csvContent = filteredNominations.value.map(nom => Object.values(nom).join(",")).join("\n");
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      saveAs(blob, "nominations.csv");
    };

    return {
      nominations,
      filteredNominations,
      fromDate,
      toDate,
      filterStatus,
      currentIndex,
      currentNomination,
      declineReasons,
      showModal,
      updateReasons,
      submitAction,
      downloadSelectedData,
      formatDate,
      applyFilters,
      formData,
      submitForm,
      formattedViewName
    };
  }
};
</script>
