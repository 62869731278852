<template>
    <div class="container mt-4">
      <h2 class="mb-4"><i class="bi bi-key-fill"></i> Change Password</h2>
      <div class="card">
        <div class="card-header">
          <h4><i class="bi bi-shield-lock-fill"></i> Update Your Password</h4>
        </div>
        <div class="card-body">
          <form @submit.prevent="changePassword">
            <div class="mb-3">
              <label for="currentPassword" class="form-label">Current Password</label>
              <input type="password" id="currentPassword" v-model="form.currentPassword" class="form-control" required>
            </div>
            <div class="mb-3">
              <label for="newPassword" class="form-label">New Password</label>
              <input type="password" id="newPassword" v-model="form.newPassword" class="form-control" required>
            </div>
            <div class="mb-3">
              <label for="confirmPassword" class="form-label">Confirm New Password</label>
              <input type="password" id="confirmPassword" v-model="form.confirmPassword" class="form-control" required>
            </div>
            <button type="submit" class="btn btn-primary">Update Password</button>
          </form>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { ref } from 'vue';
  
  export default {
    name: 'ChangePassword',
    setup() {
      const form = ref({
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
      });
  
      function changePassword() {
        // Implement password change logic here
        alert('Password change feature not implemented.');
      }
  
      return {
        form,
        changePassword,
      };
    },
  };
  </script>
  
  <style>
  .card-header h4 {
    color: #0275d8;
  }
  
  .btn-primary {
    background-color: #0275d8;
    border-color: #0275d8;
  }
  
  .btn-primary:hover {
    background-color: #0256b8;
    border-color: #0246a8;
  }
  </style>
  