<template>
    <footer class="footer mt-auto py-3 bg-light fixed-bottom">
      <div class="container">
        <span class="text-muted">© 2023 University Of London. All rights reserved.</span>
      </div>
    </footer>
  </template>
  
  <script>
    export default {
        name: 'ManagerFooter'
    };
  </script>
  
  <style>
  
  </style>
  