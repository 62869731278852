<template>
    <div class="container-fluid">
        <div class="row">
            <!-- Left-hand Sidebar -->
            <div class="col-md-2 px-0 py-3 border rounded text-white" style="background-color: #1B153E;">
                <div class="sidebar-heading">
                    <img src="/uol-logo.svg" alt="Logo" height="70" class="d-inline-block align-top">
                </div>
                <div class="mx-3 p-3 my-5 border rounded">
                    <h5>Step 2 of 3</h5>
                    <p>Please provide your bank details accurately, the bank details you provide must be from a UK bank
                        account in your own name.</p>
                    <p>Please complete the following registration form. Fields marks with * are compulsory:</p>
                    <p>Previous registration pages can be viewed using the back arrow on your browser</p>
                    <p>As you have completed step 1, your registration has been created. You can log back into UoL at
                        any
                        point to update your profile.</p>
                </div>
            </div>

            <!-- Main Content Area -->
            <div class="p-3 col-md-10">
                <header class="my-3">
                    <div class="d-flex justify-content-between align-items-center">
                        <h3 class="m-0">{{ name }}</h3>
                        <span>Updated {{ timeSinceUpdate }}</span>
                    </div>
                </header>
                <form @submit.prevent="submitForm" class="needs-validation" novalidate>
                    <!--check livingInUK-->
                    <div v-if="livingInUK !== 'false'">
                        <input type="hidden" name="id" v-model="formData.id">
                        <input type="hidden" name="rejoiner" v-model="formData.rejoiner">

                        <!-- Employment Status Section -->
                        <div class="card my-3">
                            <div class="card-header">
                                <h5>Employment Status</h5>
                            </div>
                            <div class="card-body">
                                <div class="mb-3">
                                    <label for="employmentStatus" class="form-label">Your employment status:</label>
                                    <input type="text" class="form-control" id="employmentStatus"
                                        v-model="formData.employmentStatus" readonly>
                                </div>
                            </div>
                        </div>

                        <!-- Employee Statement Section -->
                        <div class="card my-3">
                            <div class="card-header">
                                <h5>Employee Statement</h5>
                            </div>
                            <div class="card-body">
                                <p>Please advise which of the below statements applies to you A, B or C.</p>

                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="taxCode" id="statementA"
                                        value="A" v-model="formData.taxCode">
                                    <label class="form-check-label" for="statementA">
                                        <strong>Statement A</strong><br>
                                        Do not choose this statement if you're in receipt of a State, Works or Private
                                        Pension.<br>
                                        Choose this statement if the following applies. This is my first job since 6
                                        April
                                        and
                                        since the
                                        6 April I've not received payments from any of the following:
                                        <ul>
                                            <li>Jobseeker's Allowance</li>
                                            <li>Employment and Support Allowance</li>
                                            <li>Incapacity Benefit</li>
                                        </ul>
                                    </label>
                                </div>

                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="taxCode" id="statementB"
                                        value="B" v-model="formData.taxCode">
                                    <label class="form-check-label" for="statementB">
                                        <strong>Statement B</strong><br>
                                        Do not choose this statement if you're in receipt of a State, Works or Private
                                        Pension.<br>
                                        Choose this statement if the following applies. Since 6 April I have had another
                                        job
                                        but
                                        I do
                                        not have a P45 or if you have submitted your P45. And/or since the 6 April I
                                        have
                                        received
                                        payments from any of the following:
                                        <ul>
                                            <li>Jobseeker's Allowance</li>
                                            <li>Employment and Support Allowance</li>
                                            <li>Incapacity Benefit</li>
                                        </ul>
                                    </label>
                                </div>

                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="taxCode" id="statementC"
                                        value="C" v-model="formData.taxCode">
                                    <label class="form-check-label" for="statementC">
                                        <strong>Statement C</strong><br>
                                        Choose this statement if:
                                        <ul>
                                            <li>you have another job and/or</li>
                                            <li>you're in receipt of a State, Works or Private Pension</li>
                                        </ul>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <!-- Gender Information Section -->
                        <div class="card my-3">
                            <div class="card-header">
                                <h5>Gender Information</h5>
                            </div>
                            <div class="card-body">
                                <p>What is your sex?</p>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="gender" id="male" value="M"
                                        v-model="formData.gender">
                                    <label class="form-check-label" for="male">Male</label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="gender" id="female" value="F"
                                        v-model="formData.gender">
                                    <label class="form-check-label" for="female">Female</label>
                                </div>
                            </div>
                        </div>

                        <!-- Student Loan Section -->
                        <div class="card my-3">
                            <div class="card-header">
                                <h5>Student Loan</h5>
                            </div>
                            <div class="card-body">
                                <div class="card">
                                    <div class="card-header">
                                        <h5>1. Do you have one of the Student Loan Plans described below which is not
                                            fully
                                            repaid?</h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="mb-3">
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio" name="hasGradLoan"
                                                    id="hasGradLoanYes" value="Y" v-model="formData.hasGradLoan">
                                                <label class="form-check-label" for="hasGradLoanYes">Yes</label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio" name="hasGradLoan"
                                                    id="hasGradLoanNo" value="N" v-model="formData.hasGradLoan">
                                                <label class="form-check-label" for="hasGradLoanNo">No</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- Additional Student Loan Questions -->
                                <div v-if="formData.hasGradLoan === 'Y'">
                                    <!-- Similar structure for each question -->
                                    <!-- Example for one question -->
                                    <div class="card  my-3">
                                        <div class="card-header">
                                            <h5 class="card-title">2. Did you complete or leave your studies before 6th
                                                April?
                                            </h5>
                                        </div>
                                        <div class="card-body">
                                            <div class="mb-3">
                                                <div class="form-check">
                                                    <input class="form-check-input" type="radio" name="graduated"
                                                        id="graduatedYes" value="Y" v-model="formData.graduated">
                                                    <label class="form-check-label" for="graduatedYes">Yes</label>
                                                </div>
                                                <div class="form-check">
                                                    <input class="form-check-input" type="radio" name="graduated"
                                                        id="graduatedNo" value="N" v-model="formData.graduated">
                                                    <label class="form-check-label" for="graduatedNo">No</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Repaying Student Loan Directly Question -->
                                    <div class="card my-3">
                                        <div class="card-header">
                                            <h5>3. Repaying Student Loan Directly</h5>
                                        </div>
                                        <div class="card-body">
                                            <p>Are you repaying your Student Loan directly to the Student Loans Company
                                                by
                                                direct debit?
                                            </p>
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio" name="repayingDirectly"
                                                    id="repayingDirectlyYes" value="Y"
                                                    v-model="formData.repayingDirectly">
                                                <label class="form-check-label" for="repayingDirectlyYes">Yes</label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio" name="repayingDirectly"
                                                    id="repayingDirectlyNo" value="N"
                                                    v-model="formData.repayingDirectly">
                                                <label class="form-check-label" for="repayingDirectlyNo">No (Select
                                                    which
                                                    Student Loan
                                                    plan applies to you)</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card my-3">
                                        <div class="card-header">
                                            <h5 class="card-title">4. What type of Student Loan do you have?</h5>
                                        </div>
                                        <div class="card-body">
                                            <!-- Loan Plan Selection -->
                                            <div class="mb-3">
                                                <div class="form-check">
                                                    <input class="form-check-input" type="radio" name="loanPlan"
                                                        id="loanPlan1" value="1" v-model="formData.loanPlan">
                                                    <label class="form-check-label" for="loanPlan1">Plan 1</label>
                                                </div>
                                                <div class="form-check">
                                                    <input class="form-check-input" type="radio" name="loanPlan"
                                                        id="loanPlan2" value="2" v-model="formData.loanPlan">
                                                    <label class="form-check-label" for="loanPlan2">Plan 2</label>
                                                </div>
                                                <div class="form-check">
                                                    <input class="form-check-input" type="radio" name="loanPlan"
                                                        id="loanPlan4" value="4" v-model="formData.loanPlan">
                                                    <label class="form-check-label" for="loanPlan4">Plan 4</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- Loan Plan Descriptions -->
                                    <div v-if="formData.loanPlan === '1'">
                                        <p><strong>Plan 1 Student Loan</strong><br>
                                            You'll have a Plan 1 Student Loan if:
                                        <ul>
                                            <li>you lived in Scotland or Northern Ireland when you started your course
                                                (undergraduate or postgraduate)</li>
                                            <li>you lived in England or Wales and started your undergraduate course
                                                before 1
                                                September 2012.</li>
                                        </ul>
                                        </p>
                                    </div>
                                    <div v-if="formData.loanPlan === '2'">
                                        <p><strong>Plan 2 Student Loan</strong><br>
                                            You'll have a Plan 2 Student Loan if:
                                        <ul>
                                            <li>you lived in England or Wales and started your undergraduate course on
                                                or
                                                after
                                                1 September 2012</li>
                                            <li>your loan is a Part Time Maintenance Loan</li>
                                            <li>your loan is an Advanced Learner Loan</li>
                                            <li>your loan is a Postgraduate Healthcare Loan</li>
                                        </ul>
                                        </p>
                                    </div>
                                    <div v-if="formData.loanPlan === '4'">
                                        <p><strong>Plan 4 Student Loan</strong><br>
                                            You'll have a Plan 4 Student Loan if:
                                        <ul>
                                            <li>You lived in Scotland and applied through the Students Award Agency
                                                Scotland
                                                (SAAS) when you started your course.</li>
                                        </ul>
                                        </p>
                                    </div>
                                </div>
                                <!-- Post Graduate Student Loan Section -->
                                <div class="card my-3">
                                    <div class="card-header">
                                        <h5>Post Graduate Student Loan</h5>
                                    </div>
                                    <div class="card-body">
                                        <p>For more guidance about funding and repaying, go to <a
                                                href="https://www.gov.uk/funding-for-postgraduate-study"
                                                target="_blank">www.gov.uk/funding-for-postgraduate-study</a>.</p>

                                        <div class="mb-3">
                                            <p>Do you have a Postgraduate Loan which is not fully repaid?</p>
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio" name="hasPostgradLoan"
                                                    id="hasPostgradLoanYes" value="Y"
                                                    v-model="formData.hasPostgradLoan">
                                                <label class="form-check-label" for="hasPostgradLoanYes">Yes</label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio" name="hasPostgradLoan"
                                                    id="hasPostgradLoanNo" value="N" v-model="formData.hasPostgradLoan">
                                                <label class="form-check-label" for="hasPostgradLoanNo">No </label>
                                            </div>
                                        </div>

                                        <!-- Additional Postgraduate Loan Questions -->
                                        <div v-if="formData.hasPostgradLoan === 'Y'">
                                            <!-- Question 6 -->
                                            <div class="mb-3">
                                                <p>Did you complete or leave your Postgraduate studies before 6th April?
                                                </p>
                                                <div class="form-check">
                                                    <input class="form-check-input" type="radio"
                                                        name="postgradCompleted" id="postgradCompletedYes" value="Y"
                                                        v-model="formData.hasPostgradLoan">
                                                    <label class="form-check-label"
                                                        for="postgradCompletedYes">Yes</label>
                                                </div>
                                                <div class="form-check">
                                                    <input class="form-check-input" type="radio"
                                                        name="postgradCompleted" id="postgradCompletedNo" value="N"
                                                        v-model="formData.postgradCompleted">
                                                    <label class="form-check-label" for="postgradCompletedNo">No
                                                    </label>
                                                </div>
                                            </div>

                                            <!-- Question 7 -->
                                            <div class="mb-3">
                                                <p>Are you repaying your Postgraduate Loan direct to the Student Loans
                                                    Company
                                                    by
                                                    direct debit?</p>
                                                <div class="form-check">
                                                    <input class="form-check-input" type="radio"
                                                        name="repayingPostgradLoan" id="repayingPostgradLoanYes"
                                                        value="Y" v-model="formData.repayingPostgradLoan">
                                                    <label class="form-check-label"
                                                        for="repayingPostgradLoanYes">Yes</label>
                                                </div>
                                                <div class="form-check">
                                                    <input class="form-check-input" type="radio"
                                                        name="repayingPostgradLoanYes" id="repayingPostgradLoanNo"
                                                        value="N" v-model="formData.repayingPostgradLoan">
                                                    <label class="form-check-label" for="repayingPostgradLoanNo">No
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card my-3">
                        <div class="card-header">
                            <h5>Bank Details</h5>
                        </div>
                        <div class="card-body">
                            <p>The bank details you provide must be from a UK bank account which is in your own name.
                            </p>
                            <!-- Additional text can be shown conditionally if needed -->
                            <div class="row" v-if="livingInUK === 'false'">
                                <div class="mb-3 col-auto">
                                    <label for="bankName" class="form-label">Bank Name</label>
                                    <input v-model="formData.bankName" type="text" class="form-control"
                                        id="bankName">
                                </div>

                                <div class="mb-3 col-auto">
                                    <label for="accountNumber" class="form-label">Account Number</label>
                                    <input v-model="formData.accountNumber" type="text" class="form-control"
                                        id="accountNumber">
                                </div>

                                <div class="mb-3 col-auto">
                                    <label for="iban" class="form-label">IBAN</label>
                                    <input v-model="formData.iban" type="text" class="form-control"
                                        id="iban">
                                </div>

                                <div class="mb-3 col-auto">
                                    <label for="swiftCode" class="form-label">SWIFT/BIC Code</label>
                                    <input v-model="formData.swiftCode" type="text" class="form-control"
                                        id="swiftCode">
                                </div>
                                <!--add routing code-->
                                <div class="mb-3 col-auto">
                                    <label for="swiftCode" class="form-label">Routing Code</label>
                                    <input v-model="formData.routingCode" type="text" class="form-control"
                                        id="routingCode">
                                </div>
                            </div>
                            <div class="row" v-else>
                                <!-- Bank Name -->
                                <div class="col-auto mb-3">
                                    <label for="bankName" class="form-label">Bank Name:*</label>
                                    <input type="text" class="form-control" id="bankName"
                                        v-model="formData.bankName" placeholder="e.g. Halifax, Barclays"
                                        aria-label="Please Enter">
                                </div>

                                <!-- Bank Sort Code -->
                                <div class="col-auto mb-3">
                                    <label for="sortCode" class="form-label">Bank Sort Code:*</label>
                                    <input type="text" class="form-control" id="sortCode"
                                        v-model="formData.sortCode"
                                        pattern="^(?!(?:0{6}|00-00-00))(?:\d{6}|\d\d-\d\d-\d\d)$"
                                        aria-label="Please Enter">
                                </div>

                                <!-- Bank Account Number -->
                                <div class="col-auto mb-3">
                                    <label for="accountNumber" class="form-label">Bank Account Number:*</label>
                                    <input type="text" class="form-control" id="accountNumber"
                                        v-model="formData.accountNumber" aria-label="Please Enter">
                                </div>

                                <!-- Name on Account -->
                                <div class="col-auto mb-3">
                                    <label for="accountName" class="form-label">Name on this Account:*</label>
                                    <input type="text" class="form-control" id="accountName"
                                        v-model="formData.accountName" aria-label="Please Enter">
                                </div>

                                <!-- Name on Account -->
                                <div class="col-auto mb-3">
                                    <label for="rollNumber" class="form-label">Roll No (Optional):</label>
                                    <input type="text" class="form-control" id="rollNumber"
                                        v-model="formData.rollNumber" aria-label="Please Enter">
                                </div>
                            </div>

                            <p style="color: red;">Important: Your pay will be delayed if your bank details are not
                                entered
                                accurately.</p>
                        </div>
                    </div>
                    <!-- Submit Button -->
                    <div class="d-flex justify-content-between m-2">
                        <!-- Go Back to Button -->
                        <button class="btn btn-primary" @click="$router.go(-1)">&laquo; Step 1</button>
                        <button type="submit" class="btn btn-primary">Step 3 &raquo;</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
import { ref, onMounted, computed } from 'vue';
import { generateClient } from 'aws-amplify/api';
import { contractorRegisterStep2Input } from '@/graphql/mutations';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

export default {
    setup() {
        const store = useStore();
        const timeSinceUpdate = ref('1 minute');
        const name = ref('');
        const studentVisaModal = ref(null);
        const client = generateClient();
        const formData = computed(() => store.state.step2Data);
        const router = useRouter();

        const showName = () => {
            name.value = localStorage.getItem('name') || '';
        };

        const submitForm = async () => {
            const userId = localStorage.getItem('userid');
            if (!userId) {
                alert('User ID is not available. Please make sure you are logged in.');
                return;
            }

            // Prepare your updateData structure based on your form data
            const updateData = {
                id: userId,
                ...formData.value, // Make sure formData.value has all the necessary fields
            };

            try {
                const response = await client.graphql({
                    query: contractorRegisterStep2Input, // Use the mutation object you defined
                    variables: {
                        id: userId,
                        input: updateData,
                    },
                });

                // Check the response structure based on your GraphQL server's response
                if (response.data && response.data.contractorRegisterStep2Input) {
                    console.log('Update successful:', response.data.contractorRegisterStep2Input);
                    // alert('Details updated successfully');
                    // Redirect to the next step
                    router.push('/register/step3');
                } else {
                    console.error('Update did not return expected results.');
                }
            } catch (error) {
                console.error('Error during update:', error);
                // alert('Failed to update details: ' + error.message);
            }
        };

        onMounted(() => {
            showName();
            // Additional logic or user data fetching could be done here
        });

        return {
            formData: store.state.step2Data,
            timeSinceUpdate,
            name,
            studentVisaModal,
            submitForm,
        };
    },
};
</script>

<style>
/* Optional styling */
header {
    background-color: #f8f9fa;
    padding: 10px;
    border-radius: 5px;
}
</style>
