<template>
  <header class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container-fluid">
      <div v-if="user" class="d-flex align-items-center justify-content-center flex-grow-1">
        <div class="btn-group" role="group" aria-label="User info and actions" style="margin-left: 150px;">
          <button type="button" class="btn btn-light text-primary shadow">
            <i class="bi bi-person-fill me-3"></i>
            {{ user.firstName }} {{ user.lastName }}
            <i class="bi bi-grip-vertical mx-2"></i>
            Profile: {{ user.profileType }}
            <i class="bi bi-grip-vertical mx-2"></i>
            {{ user.federationMember }}
          </button>

          <!-- Switch User Dropdown -->
          <div class="dropdown">
            <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
              Switch User
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <li v-if="userType !== 'C'"><a class="dropdown-item" href="#" @click="switchUser('C')">Consultant</a></li>
              <li v-if="userType !== 'M'"><a class="dropdown-item" href="#" @click="switchUser('M')">Programme Manager</a></li>
            </ul>
          </div>
        </div>
      </div>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><router-link to="/contractor/dashboard">Home</router-link></li>
          <li class="breadcrumb-item active" aria-current="page">{{ $route.name }}</li>
        </ol>
      </nav>
    </div>
  </header>
</template>

<script>
import { ref, onMounted } from 'vue';

export default {
  name: 'ContractorHeader',
  setup() {
    const user = ref({
      firstName: 'Prof. David',
      lastName: 'Smith',
      profileType: 'Academic Contractor',
      federationMember: 'University of London',
    });

    const userType = ref('');

    // Ensure userType is initialized and updated properly
    const getUserType = () => {
      let type = localStorage.getItem('userType');
      if (!type) {
        const path = window.location.pathname;
        type = path.includes('/contractor') ? 'AC' : 'M';
        localStorage.setItem('userType', type);
      }
      userType.value = type;
    };

    const switchUser = (type) => {
      localStorage.setItem('userType', type);
      userType.value = type;
      window.location.href = '/manager/';
    };

    onMounted(() => {
      getUserType();
    });

    return {
      user,
      userType,
      switchUser,
    };
  },
};
</script>

<style>
/* Header Styles */
.navbar {
  margin-bottom: 20px;
}

.breadcrumb {
  margin-bottom: 0;
}
</style>
