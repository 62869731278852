<template>
  <header class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container-fluid">
      <div class="d-flex align-items-center justify-content-center flex-grow-1">
        <div class="btn-group" role="group" aria-label="User info and actions" style="margin-left: 150px;">
          <button type="button" class="btn btn-light text-primary shadow">
            <i class="bi bi-person-fill me-3"></i>
            {{ user.firstName }} {{ user.lastName }}
            <i class="bi bi-grip-vertical mx-2"></i>
            Profile: {{ user.profileType }}
            <i class="bi bi-grip-vertical mx-2"></i>
            {{ user.federationMember }}
          </button>

          <!-- Switch User Dropdown -->
          <div class="dropdown">
            <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
              Switch User
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <li v-if="userType !== 'C'"><a class="dropdown-item" href="#" @click="() => switchUser('C')">Consultant</a></li>
              <li v-if="userType !== 'M'"><a class="dropdown-item" href="#" @click="() => switchUser('M')">Programme Manager</a></li>
              <li v-if="userType !== 'AC'"><a class="dropdown-item" href="#" @click="() => switchUser('AC')">Academic Contractor</a></li>
            </ul>
          </div>
        </div>
      </div>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><router-link to="/manager/">Home</router-link></li>
          <li class="breadcrumb-item active" aria-current="page">{{ $route.name }}</li>
        </ol>
      </nav>
    </div>
  </header>
</template>

<script>
import { ref, reactive, onMounted } from 'vue';

export default {
  name: 'ManagerHeader',
  setup() {
    const userType = ref(localStorage.getItem('userType') || 'M');  // Default to 'M' for manager
    const user = reactive({
      firstName: 'John',
      lastName: 'Johnson',
      profileType: '',
      federationMember: 'University of London',
    });

    function getUserType() {
      let storedType = localStorage.getItem('userType');
      if (!storedType) {
        const path = window.location.pathname;
        storedType = path.includes('/contractor') ? 'AC' : path.includes('/manager') ? 'M' : 'C';
        localStorage.setItem('userType', storedType);
      }
      userType.value = storedType;
      updateUserProfile(storedType);
    }

    function updateUserProfile(type) {
      user.profileType = type === 'C' ? 'Consultant' : type === 'M' ? 'Programme Manager' : 'Academic Contractor';
    }

    function switchUser(type) {
      localStorage.setItem('userType', type);
      userType.value = type;
      updateUserProfile(type);
      if (type === 'C' || type === 'M') {
        window.location.href = '/manager/';
      } else if (type === 'AC') {
        window.location.href = '/contractor/my-profile';
      }
    }

    onMounted(getUserType);

    return { user, userType, switchUser };
  },
};
</script>
